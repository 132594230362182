/* -------------------------- Selection Section -------------------------- */

.selection-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  padding-bottom: 2.5rem;
  padding-top: 4rem;
  height: calc(92vh - 160px);
  background-color: #1f1f1f;
}

.selection-left {
  flex: 1;
  max-width: 15%;
  margin-right: 1.25rem;
  margin-left: 6rem;
  text-align: justify;
}

.selection-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #d7b46a;
  margin-bottom: 1.25rem;
}

.selection-thumbnail {
  width: 100%;
  max-width: 170px;
  height: auto;
  margin-bottom: 0.9375rem;
  border: 2px solid #d7b46a;
}

.selection-style-name {
  font-size: 1.5rem;
  font-weight: 400;
  color: #d7b46a;
  margin-bottom: 1.25rem;
}

.instructions h4 {
  font-size: 1.2rem;
  margin-bottom: 0.625rem;
  color: #d7b46a;
  text-decoration: underline;
}

.instructions ul {
  margin-left: 1.5em;
  list-style-type: circle;
  padding: 0;
}

.instructions li {
  margin-bottom: 0.625rem;
  color: #d7b46a;
}

.selection-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  max-width: 70%;
  margin-right: 6rem;
}

.selection-large-image {
  width: 100%;
  max-height: 70vh;
  height: auto;
  object-fit: cover;
  border: 5px solid #d7b46a;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  margin-bottom: 1.25rem;
}

.start-button {
  background-color: #d7b46a;
  color: #1f1f1f;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.9375rem 1.875rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1px;
  margin-bottom: 2px;
  align-self: flex-end;
}

.start-button:hover {
  background-color: #b6953c;
  transition: background-color 0.3s ease;
}

/* Responsivitat */
@media (max-width: 768px) {
  .selection-container {
    flex-direction: column;
    padding: 1.5rem;
  }

  .selection-left {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }

  .selection-right {
    max-width: 100%;
  }

  .selection-title {
    font-size: 1.5rem;
  }

  .selection-style-name {
    font-size: 1.2rem;
  }
}
