.registration-successful-container {
  display: flex;
  min-height: 100vh;
}

.image-section {
  flex: 1;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registration-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-section {
  position: relative;
  flex: 1;
  background-color: #333;
  color: #d7b46a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
}

.brand-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 4rem;
  font-weight: 300;
  color: #d7b46a;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.message-section h2 {
  font-size: 1.25rem;
  color: #d7b46a;
}

.message-section p {
  font-size: 1rem;
  margin: 1.25rem 0;
  color: #d7b46a;
}

.message-section button {
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  background-color: #d7b46a;
  color: #333;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.message-section button:hover {
  background-color: #b5974f;
  color: #222;
}

/* Responsivitat per a 320px */
@media (max-width: 320px) {
  .registration-successful-container {
    flex-direction: column;
  }

  .brand-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    position: static;
    transform: none;
    top: auto;
    left: auto;
  }

  .reg-symbol {
    font-size: 0.7em;
  }
}

@media (max-width: 375px) {
  .registration-successful-container {
    flex-direction: column;
  }

  .brand-title {
    font-size: 2rem; 
    margin-bottom: 1rem;
    text-align: center;
    position: static;
    transform: none;
    top: auto;
    left: auto;
  }

  .reg-symbol {
    font-size: 0.8em;
  }

  .message-section h2 {
    font-size: 1.2rem; 
    margin-top: 1rem;
  }

  .message-section p {
    font-size: 0.85rem; 
    margin: 1rem 0;
  }

  .message-section button {
    font-size: 0.85rem; 
    padding: 0.6rem 1.2rem; 
  }
}


/* Adaptació per a dispositius mòbils */
@media (max-width: 480px) {
  .brand-title {
    font-size: 2.5rem;
    top: 5%;
  }

  .message-section h2 {
    font-size: 1rem;
  }

  .message-section p {
    font-size: 0.8rem;
    margin: 1rem 0;
  }

  .message-section button {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
  }
}


/* Adaptació per a dispositius tablets */
@media (max-width: 768px) {
  .brand-title {
    font-size: 3rem;
    top: 8%;
  }

  .message-section h2 {
    font-size: 1.1rem;
  }

  .message-section p {
    font-size: 0.9rem;
  }

  .message-section button {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
}

/* Estils per a pantalles grans */

/* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .login-button {
    font-size: 1rem !important;
    padding: 1.2rem 3rem !important;
    border-radius: 10px;
    width: auto;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
  .login-button {
    font-size: 1.3rem !important;
    padding: 1.4rem 3rem !important;
    border-radius: 10px;
    width: auto;
  }
}
