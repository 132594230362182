.cartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem 1.25rem;
  max-width: 22.25rem;
  margin: 2.5rem auto;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cartContainer h4 {
  font-size: 1.3rem;
  margin-bottom: 1.25rem;
  color: #333;
  background-color: #d7b46a;
  border-radius: 5px;
  padding: 0.625rem 1.25rem;
  width: calc(100% - 2.5rem);
  text-align: center;
}

.cartItem {
  background-color: #ECECEC;
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 5px;
}

.cartItemImage {
  padding-top: 2rem;
  width: 90%;
  margin: 0 auto;
  display: block;
}

.cartItemText {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}

.cartItemVariant {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.cartItemPrice {
  font-size: 1rem;
  color: #333;
}

.quantityControls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 80%;
  margin: auto;
}

.quantityControls button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #d7b46a;
  border: none;
  border-radius: 4px;
  color: #333;
  margin-bottom:1rem;
}

.quantityControls button:hover {
  background-color: #333;
  color: #d7b46a;
}

.summaryTotal {
  margin-top: 1rem;
  background-color: #ECECEC;
  padding-top: 0.1rem;
  padding-bottom: 1rem;
  border-radius: 5px;
}

.shippingCost {
  width: 65%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  font-size: 1rem;
  
}

.summaryRow {
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  font-size: 1rem;
}

.shippingCost p {
  font-size: 1rem;
  color: #333;
}

.emptyCart {
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyCartImage {
  width: 320px;
  height: auto;
}


.goToGalleryButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.goToGalleryButton:hover {
  background-color: #d7b46a;
  color: #333;
}

.cartNavigation {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.continueShoppingButton,
.proceedToCheckoutButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  background-color: #d7b46a;
  color: #333;
  width: 100%;
}

.continueShoppingButton:hover,
.proceedToCheckoutButton:hover {
  background-color: #333;
  color: #d7b46a;
}



@media (max-width: 768px) {
  .cartContainer {
    padding: 1.25rem;
    margin: 1.5rem auto;
  }

  .cartContainer h4 {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .cartContainer {
    max-width: 90%;
    padding: 1rem;
    margin: 1rem auto;
  }

  .cartContainer h4 {
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
  }

  .cartItemImage {
    width: 100%;
    padding-top: 1rem;
  }

  .quantityControls {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .quantityControls button {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
  }

  .summaryTotal {
    padding: 0.5rem;
  }

  .goToGalleryButton {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .cartNavigation {
    flex-direction: column;
    gap: 0.5rem;
  }

  .continueShoppingButton,
  .proceedToCheckoutButton {
    padding: 8px 16px;
    font-size: 0.8rem;
  }
  .cartItemActions .removeButton {
    width:90%;
  }

}

@media (max-width: 320px) {
  .cartContainer {
    flex-direction: column;
    padding: 1rem;
    margin: 1rem auto;
    max-width: 100%;
  }

  .cartContainer h4 {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .cartItem {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }

  .cartItemImage {
    width: 90%;
    padding-top: 1rem;
  }

  .quantityControls {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.3rem;
  }

  .quantityControls button {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }


  .summaryTotal {
    padding: 0.3rem;
  }

  .goToGalleryButton {
    padding: 10px 12px;
    font-size: 0.8rem;
    width: 80%;
  }

  .cartNavigation {
    flex-direction: column;
    gap: 0.3rem;
  }

  .continueShoppingButton,
  .proceedToCheckoutButton {
    padding: 10px 12px;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .cartItemActions .removeButton {
    width:80%;
  }
}

@media (min-width: 1921px) {
  .cartContainer {
    max-width: 37rem;
    padding: 3rem;
  }

  .cartContainer h4 {
    font-size: 2rem;
    padding: 1rem 2rem;
  }

  .cartItem {
    margin-top: 3rem;
    padding-bottom: 2.5rem;
    font-size: 1.5rem;
  }

  .quantityControls button {
    font-size: 1.2rem;
    padding: 0.8rem 1.5rem;
  }

  .summaryTotal {
    padding: 1.5rem;
    font-size: 1.5rem;
  }

  .goToGalleryImage,
  .continueShoppingButton,
  .proceedToCheckoutButton {
    font-size: 1.4rem;
    padding: 1rem 2rem;
    border-radius: 10px;
  }

  .emptyCartImage {
    width: 640px;
    height: auto;
  }
}
