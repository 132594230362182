/* ProductCard.css */
.productGrid {
  display: grid;
  grid-template-columns: minmax(100px, 12.5rem) 1fr; 
  gap: 1.25rem;
  align-items: start;
  width: 100%;
}

.productContainer {
  margin-bottom: 1rem; 
  border-bottom: 1px solid #ccc; 
  padding-bottom: 2rem; 
}

.productContainer:last-child {
  margin-bottom: 0; 
  border-bottom: none; 
}

.productImage {
  width: 100%;
  height: auto;
  max-height: 11.25rem;
  border-radius: 8px;
  object-fit: cover;
}

.productDetails {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 0.625rem;
}

.productInfo {
  display: grid;
  gap: 0.3125rem;
}

.productPrice {
  font-size: 1.125rem;
  color: #333;
  font-weight: bold;
  margin-top: 0.3125rem;
}

.productName {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.productSize {
  font-size: 0.875rem;
  color: #666;
}

.quantityControls {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.625rem;
  max-width: 240px;
}

.quantityControls button {
  background-color: #e0e0e0;
  color: #1e1f1f;
  border: none;
  padding: 5px 10px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.quantityControls button:hover {
  background-color: #d7b46a;
}

.quantityControls span {
  font-size: 1rem;
  font-weight: bold;
}

.cartActions {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.cartActions button {
  background: none;
  border: none;
  color: #1e1f1f;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.75rem;
  transition: color 0.3s ease;
}

.cartActions button:hover {
  color: #d7b46a;
}

.removeButton {
  background-color: #1e1f1f;
  color: #d7b46a;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.removeButton:hover {
  background-color: #d7b46a;
  color: #1e1f1f;
}

/* Responsivitat per a 769px a 1024px */
@media (min-width: 769px) and (max-width: 1024px) {
  .productGrid {
    grid-template-columns: minmax(120px, 10rem) 1fr; 
    gap: 1rem; 
  }

  .productContainer {
    padding-bottom: 1rem; 
  }

  .productImage {
    max-height: 12rem; 
  }

  .quantityControls {
    max-width: 250px; 
  }
}

/* Responsivitat per a pantalles més petites */
@media (max-width: 768px) {
  .productGrid {
    grid-template-columns: minmax(80px, 10rem) 1fr; 
    gap: 1rem;
  }

  .productImage {
    max-height: 12rem;
  }

  .productPrice {
    font-size: 1rem;
  }

  .productName {
    font-size: 1rem;
  }

  .productSize {
    font-size: 0.875rem;
  }

  .quantityControls {
    max-width: 200px;
  }
}
