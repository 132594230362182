/* ---------------------------- AuthPage ---------------------------- */

.auth-page {
  display: flex;
  min-height: 100vh;
  max-height: 78rem;
}

.auth-image-section {
  flex: 1;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth-form-section {
  position: relative;
  flex: 1;
  background-color: #333;
  color: #d7b46a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.brand-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 4rem;
  font-weight: 300;
  color: #d7b46a;
  text-align: center;
  position: absolute;
  margin-top: 0rem;
  transform: translateX(-50%);
}

.auth-toggle {
  cursor: pointer;
  color: #d7b46a;
  text-decoration: underline;
  padding-top: 0rem;
}

/* Responsivitat per dispositius petits */
@media (min-width: 320px) and (max-width: 375px) {
  .brand-title {
    font-size: 1.2rem; /* Redueix la mida de la font */
    margin-top: 0px; 
    text-align: center; 
    transform: translateX(-50%); /* Manté l'alineació central */
  }

  .auth-form-section {
    padding: 0.5rem; /* Redueix el padding per pantalles petites */
  }

  .consent-label {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .auth-page {
    flex-direction: column;
  }

  .auth-form-section {
    padding: 1.5rem;
  }

  .brand-title {
    font-size: 1.5rem; /* Ajusta la mida de la font */
    top: 5%;
  }
}

/* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .already-account {
    font-size: 1.2rem;
    margin-top: -2rem;
    padding-top: 0;
    text-align: center;
  }
  
  .auth-toggle {
    color: #d7b46a;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
  
  .auth-toggle:hover {
    color: #b5974f;
  }
  

  .google-login-button {
    font-size: 1.1rem;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 40%;
    margin-bottom: 8rem;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
  .already-account {
    font-size: 1.5rem;
  }
  
  .google-login-button {
    font-size: 1.6rem;
    padding: 1.2rem 2.5rem;
    border-radius: 10px;
  }
}
