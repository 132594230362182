/* General styles */
.order-history-page {
  display: flex;
  min-height: 100vh;
}

.order-image-section {
  flex: 1;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-details-section {
  position: relative;
  flex: 1;
  background-color: #333;
  color: #d7b46a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.order-details-section h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 3rem;
  font-weight: 300;
  color: #d7b46a;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.order-list {
  width: 100%;
  max-width: 750px;
  margin-top: 1rem;
}

.order-item {
  background-color: #444;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  color: black;
}

.order-item h3 {
  margin: 0;
}

/* Table styles */
.order-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9rem;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
}

.order-table th,
.order-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.order-table tr,
.order-table th {
  color: black;
}

.order-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.order-table tbody tr:hover {
  background-color: #f1f1f1;
}

.order-thumbnail-img {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 4px;
}

.order-table button {
  background-color: #ff9800;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.order-table button:hover {
  background-color: #e68900;
}

/*---------------------------- Responsive----------------------------- */

/*  max width of 768px */
@media (max-width: 768px) {
  .order-history-page {
    flex-direction: column;
    align-items: center;
  }

  .order-details-section {
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    text-align: center;
  }

  .order-details-section h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .order-details-section p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .order-list {
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;
    overflow-x: auto;
  }

  .order-table {
    width: 100%;
    min-width: 600px; 
    font-size: 0.85rem;
    border-collapse: collapse;
  }

  .order-table th,
  .order-table td {
    padding: 10px 12px;
  }

  .order-thumbnail-img {
    width: 6rem;
    height: 6rem;
  }

  .order-table button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }

  .errorMessage {
    font-size: 0.75rem;
    margin-left: 0;
  }

  .no-orders-message {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 20px;
    padding: 10px;
  }
}

/* max width of 480px */
@media (max-width: 480px) {
  .order-table,
  .order-table thead,
  .order-table tbody,
  .order-table th,
  .order-table td,
  .order-table tr {
    display: block;
  }

  .order-table thead {
    display: none;
    
  }

  .order-table tr {
    margin-bottom: 1rem;
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 10px;
  }

  .order-table td {
    padding-left: 20%;
    position: relative;
    text-align: left;
    
  }

  .order-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.7rem;
  }

  .order-thumbnail-img {
    width: 4rem;
    height: 4rem;
  }

  .order-table button {
    padding: 6px 8px;
    font-size: 0.8rem;
  }
}

/* max width of 375px */
@media (max-width: 375px) {
  .order-table th,
  .order-table td {
    padding: 6px;
    font-size: 0.7rem;
  }

  .order-thumbnail-img {
    width: 3rem;
    height: 3rem;
  }

  .order-table button {
    padding: 4px 6px;
    font-size: 0.7rem;
  }

  .order-details-section h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .order-details-section p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
}

/* max width of 320px */
@media (max-width: 320px) {
  .order-table th,
  .order-table td {
    padding: 4px;
    font-size: 0.65rem;
  }

  .order-thumbnail-img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .order-table button {
    padding: 4px 6px;
    font-size: 0.65rem;
  }

  .order-details-section h1 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .order-details-section p {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
  }
}
