/*------------------------------------------------ Pàgina inici (Showreel) --------------------------------*/

.showreel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #1f1f1f;
  color: #d7b46a;
}

.image-container {
  flex: 1;
  margin-right: 20px;
  margin-left: 15%;
  position: relative;
  overflow: hidden;
}

.showreel-image {
  width: 90%;
  object-fit: cover;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  transition: transform 1s ease-in-out;
}

.showreel-image:hover {
  transform: scale(1.07);
}

.text-container {
  flex: 1;
  margin-right: 12%;
  margin-left: -1%;
  text-align: left;
  color: #d7b46a;
}

.text-container h1 {
  font-size: 3em;
  margin-bottom: 20px;
  font-family: "Bebas Neue", sans-serif;
}

.text-container p {
  font-size: 16px;
  color: #d7b46a;
}

.text-container a {
  color: #d7b46a;
  text-decoration: underline;
}


/* Adaptació per a tablets */
@media (max-width: 768px) {
  .showreel-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .image-container,
  .text-container {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .showreel-image {
    width: 100%;
  }

  .text-container h1 {
    font-size: 30px;
  }

  .text-container p {
    font-size: 14px;
  }
}

@media (min-width: 1921px) {
  .text-container p {
    font-size: 26px;
  }

  .text-container a {
    font-size: 26px;
  }
}


@media (min-width: 2561px) {
  .text-container p {
    font-size: 30px;
  }

  .text-container a {
    font-size: 30px;
  }
}

