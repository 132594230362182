/* Overlay del modal */
.this-was-added-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

/* finestra del modal */
.this-was-added-window {
  position: absolute;
  top: 10vh; 
  bottom: 10vh; 
  max-height: 80vh; 
  padding: 5px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
  z-index: 2000;
  overflow-y: auto; 
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.this-was-added-window h2 {
  font-size: 1.1rem;
  margin: 0;
}

.close-button {
  background-color: #d7b46a;
  border: none;
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
  padding: 2px;
  max-width: 3rem;
  margin-top: 2rem;
}

.close-button:hover {
  color: #d7b46a;
  background-color:#333;
}

.artwork-preview {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  margin-top:0;
}

.order-details {
  margin-top: 0px;
 
}

.order-size,
.order-quantity,
.order-price {
  margin-bottom: 0px;
 
}

label {
  font-size: 0.9rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

select,
input {
  width: 100%;
  padding: 8px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.buy-button {
  background-color: #d7b46a;
  color: #1f1f1f;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 15px;
}

.buy-button:hover {
  background-color: #1f1f1f;
  color: #d7b46a;
}

/* Adaptació per a tablets */
@media (max-width: 768px) {
  .this-was-added-window {
    max-width: 280px;
    padding: 15px;
  }

  .this-was-added-window h2 {
    font-size: 1.1rem;
  }

  .buy-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

/* Adaptació per a mòbils */
@media (max-width: 480px) {
  .this-was-added-window {
    max-width: 240px;
    padding: 10px;
  }

  .this-was-added-window h2 {
    font-size: 1rem;
  }

  .buy-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}
