/* Profile Section */
.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  background-color: #ececec;
  color: #1f1f1f;
}

.profile-title {
  font-size: 3.9rem;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 300;
  width: 80%;
  margin: auto;
  text-align: center;
  line-height: 1;
  padding-bottom: 2rem;
  background: 50% 100%/50% 50% no-repeat
    radial-gradient(ellipse at bottom, #1f1f1f, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 2.3px;
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
  animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
}

/* Paragraph */
.profile-section p {
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  margin-top: 1em;
  margin-bottom: 1.5em;
  width: 70%;
  margin: auto;
  text-align: justify;
  column-count: 2;
  column-gap: 6em;
  line-height: 1.5;
}

/* Hello Icon */
.hello-icon {
  width: 2rem;
  margin-top: -1.1rem;
  margin-right: 0.3rem;
}

/* Animations */
@-webkit-keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}

@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}

@-webkit-keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}

@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}

/* Adaptació per a dispositius tablets */
@media (max-width: 768px) {
  .profile-title {
    font-size: 2.5rem;
    letter-spacing: 1.5px;
  }

  .profile-section p {
    font-size: 0.8rem;
    column-count: 1;
    width: 90%;
  }

  .hello-icon {
    width: 1.8rem;
    margin-top: -0.8rem;
  }
}

/* Adaptació per a dispositius mòbils */
@media (max-width: 480px) {
  .profile-title {
    font-size: 2rem;
    letter-spacing: 1px;
    padding-bottom: 1rem;
  }

  .profile-section p {
    font-size: 0.75rem;
    width: 90%;
    margin: 1em auto;
    column-gap: 3em;
  }

  .hello-icon {
    width: 1.5rem;
    margin-top: -0.6rem;
  }
}
