.success-page {
  display: flex;
  min-height: 100vh;
}

.success-image-section {
  flex: 1;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.success-details-section {
  position: relative;
  flex: 1;
  background-color: #333;
  color: #d7b46a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.success-details-section h1 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 3rem;
  font-weight: 300;
  color: #d7b46a;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.success-details-section p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.success-home-button {
  background-color: #d7b46a;
  color: #333;
  border: none;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 2rem;
  font-size: 0.95rem;
  font-weight: bold;
}

.success-home-button:hover {
  background-color: #000000;
  color: #d7b46a;
}

.order-summary {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 3rem;
  width: 100%;
  max-width: 80%; 
}

.order-summary h2{
  font-size:1.3rem;
}

.info-items,
.info-shipping {
  background-color: #ececec;
  padding: 1.5rem; 
  border-radius: 5px;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.order-item-details {
  display: flex;
  align-items: center;
  width: 100%;
}

.order-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 5px;
}

.order-item-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-item-text span {
  font-size: 0.9rem;
  color: #333;
  
}
.info-shipping p{
  font-size:1rem;
  padding-bottom:2rem;
  padding-top:1rem;
}

/* Responsivitat per a pantalles petites */
@media (max-width: 320px) {
  .success-page {
    flex-direction: column;
    display: block;
  }

  .success-image-section {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .success-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .success-details-section {
    width: 100%;
    max-width: 100%;
    padding: 1rem 0.5rem;
  }

  .success-details-section h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .success-details-section p {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .success-home-button {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }

  .order-summary {
    display: block;
    gap: 1rem;
    padding: 1rem;
  }

  .info-items,
  .info-shipping {
    padding: 1rem;
    font-size: 0.85rem;
  }

  .order-item {
    padding: 0.5rem;
  }

  .order-item-image {
    width: 40px;
    height: 40px;
  }

  .order-item-text span {
    font-size: 0.8rem;
  }
}

/* Responsivitat per a pantalles de 375px */
@media (max-width: 375px) {
  .success-details-section {
    max-width: 100%;
    padding: 1rem;
  }

  .success-details-section h1 {
    font-size: 1.8rem;
  }

  .order-summary {
    gap: 1.5rem;
  }

  .info-items,
  .info-shipping {
    padding: 1rem 0.8rem;
  }
}

/* Responsivitat per a pantalles de 480px */
@media (max-width: 480px) {
  .success-details-section {
    max-width: 90%;
    padding: 1.5rem;
  }

  .success-details-section h1 {
    font-size: 1.8rem;
  }

  .order-summary {
    gap: 2rem;
  }

  .info-items,
  .info-shipping {
    padding: 1.2rem;
    font-size: 0.95rem;
  }
}

/* Responsivitat per a pantalles de 768px */
@media (max-width: 768px) {
  .success-page {
    flex-direction: column;
    align-items: center;
  }

  .success-details-section {
    max-width: 100%;
    padding: 2rem;
  }

  .success-details-section h1 {
    font-size: 2rem;
  }

  .order-summary {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .info-items,
  .info-shipping {
    padding: 1.5rem;
    font-size: 1rem;
  }

  .order-item {
    padding: 1rem;
  }

  .order-item-text span {
    font-size: 1rem;
  }
}



