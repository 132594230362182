/* OrderSummary.css */


.summarySection {
  padding-top: 20px;
  width: 100%; 
}

.summaryTitle {
  font-size: 1.5rem; 
  margin-bottom: 20px;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.divider {
  margin-top: 15px;
  border-top: 2px solid #ccc;
}

.totalRow {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.125rem; 
  color: #000;
}

.currencyNotice {
  padding-top: 3rem;
  font-size: 0.875rem; 
  color: #999;
  text-align: justify;
}

.noticeHighlight {
  color: #e63946;
  font-weight: bold;
}

/* Responsivitat per a 769px a 1024px */
@media (min-width: 769px) and (max-width: 1024px) {
  .summaryTitle {
    font-size: 1.75rem;
  }

  .summaryRow {
    font-size: 1rem;
  }

  .totalRow {
    font-size: 1.25rem;
  }

  .currencyNotice {
    font-size: 0.9rem; 
    padding-top: 2rem;
  }
}

/* Responsivitat per a pantalles més petites */
@media (max-width: 768px) {
  .summaryTitle {
    font-size: 1.25rem; 
  }

  .summaryRow {
    font-size: 0.95rem;
  }

  .totalRow {
    font-size: 1rem;
  }

  .currencyNotice {
    font-size: 0.8rem;
    padding-top: 2rem;
  }
}
