/* Canvas del p5.js */
canvas {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh; 
  display: block;
}

/* Botó per guardar la personalització */
.finish-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  padding: 10px 20px;
  background-color: #d7b46a;
  color: #1f1f1f;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.finish-button:hover {
  background-color: #1f1f1f;
  color: #d7b46a;
}

.finish-button:active {
  background-color: #900;
}

/* Botó per guardar la personalització */
.finish-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  padding: 10px 20px;
  background-color: #d7b46a;
  color: #1f1f1f;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.finish-button:hover {
  background-color: #1f1f1f;
  color: #d7b46a;
}

.finish-button:active {
  background-color: #900;
}
