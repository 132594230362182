.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
}

.modal-content {
    background-color: #ECECEC;
    color: #1e1f1f;
    padding: 30px;
    border-radius: 12px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    position: relative;
    line-height: 1.3;
    font-family: 'Arial', sans-serif;
   
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 28px;
    color: #ECECEC;
    cursor: pointer;
}

h2 {
    margin-bottom: 0px;
    font-size: 1.8rem;
    padding-bottom: 5px;
    text-align: left; 
}

.modal-overlay h3 {
    margin-top: 20px;
    font-size: 1.3rem;
    text-decoration: underline;
    text-align: left !important; 
    padding-bottom: 0px;

}

p {
    margin-bottom: 15px;
    font-size: 1rem;
    text-align: justify !important; 
}

a {
    color: #d4af37;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media (max-width: 320px) {
    .modal-content {
      width: 90%;
      padding: 15px;
      font-size: 0.8rem;
    }
  
    h2 {
      font-size: 1.4rem;
    }
  
    h3 {
      font-size: 1rem;
    }
  
    p {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 375px) {
    .modal-content {
      width: 90%;
      padding: 20px;
      font-size: 0.85rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 480px) {
    .modal-content {
      width: 85%;
      padding: 20px;
      font-size: 0.9rem;
    }
  
    h2 {
      font-size: 1.6rem;
    }
  
    h3 {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 768px) {
    .modal-content {
      width: 80%;
      padding: 25px;
      font-size: 1rem;
    }
  
    h2 {
      font-size: 1.7rem;
    }
  
    h3 {
      font-size: 1.3rem;
    }
  }
  
  @media (min-width: 1921px) {
    .modal-content {
      width: 60%;
      padding: 40px;
      font-size: 1.1rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    h3 {
      font-size: 1.5rem;
    }
  }
  
  @media (min-width: 2561px) {
    .modal-content {
      width: 50%;
      padding: 50px;
      font-size: 1.2rem;
    }
  
    h2 {
      font-size: 2.5rem;
    }
  
    h3 {
      font-size: 1.8rem;
    }
  }
  
