
/*--------------------------------- signe marca registrada ---------------------------------------*/
.reg-symbol {
  font-size: 0.8em;
  color: #d7b46a;
  vertical-align: super;
}

/*-------------- Estils per als components Photography - Frontend - MotionGraphics ----------------*/
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  list-style: none;
  padding: 0.625rem;
  margin: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.dropdown-menu li {
  margin-bottom: 0.625rem;
}

.dropdown-menu li:last-child {
  margin-bottom: 0;
}

.dropdown-menu a {
  text-decoration: none;
  color: #333;
}

/*-------------------------------- Photography ---------------------------------------------*/
.photography-section {
  position: relative;
  height: 50vh;
  width: 100%;
  overflow: hidden;
}

.hero-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: url("../src/assets/photography.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.hero-image::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.content-wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  color: #d7b46a;
  z-index: 2;
}

.title {
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: -0.05em;
}

.description {
  font-size: 1.063rem;
  letter-spacing: -0.26px;
  margin-top: 1.25rem;
}

.call-to-action {
  margin-top: 2.5rem;
}

button {
  background: #1f1f1f;
  color: #d7b46a;
  padding: 0.75rem 2.875rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #d7b46a;
  color: #3a3a3a;
}

/*-------------- serveix de patró per a les diferents 3 seccions -------------------------------- */
/*------------------------ SERVICE SECTION PHOTO -------------------------------------------------*/

.servicesIcons {
  background-color: #1f1f1f;
  display: flex;
  width: 100%;
  color: #d7b46a;
  text-align: center;
  letter-spacing: -0.27px;
  justify-content: center;
  padding: 2.875rem 0;
  font: 700 1.125rem/1.875rem Montserrat, sans-serif;
  gap: 20rem;
}

.serviceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color:#d7b46a;
}

.serviceIcon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 8.125rem;
  transition: filter 0.3s ease-in-out;
}

.serviceItem .serviceIcon:hover {
  filter: brightness(90%);
}

.serviceTitle {
  margin-top: 1.313rem;
  color:#d7b46a;
}

.serviceItem.active .serviceIcon {
  filter: grayscale(100%);
}

.serviceItem .serviceIcon {
  filter: grayscale(0%);
}

.serviceItem.active {
  background-color: transparent;
  border-radius: 50%;
}

/* PhotoCorporatePortaits */

.photo-explanation {
  background: #ececec;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1.5rem;
  padding-bottom: 2.4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom:2rem;
}

.photo-flexContainer {
  display: flex;
  width: 100%;
  gap: 1.25rem;
}

.photo-leftColumn {
  width: 25%;
  text-align: justify;
}

.photo-rightColumn {
  width: 60%;
}

.photo-contentWrapper {
  font-family: "Montserrat", sans-serif;
}

.photo-title {
  font-size: 2rem;
  font-weight: bold;
  padding-top: 2rem;
}

.photo-description {
  margin-top: 1.25rem;
  line-height: 1.5;
  font-size: 0.97rem;
}

.photo-ctaButton {
  margin-top: 1.875rem;
  padding: 0.75rem 1.5rem;
  background-color: #1e1f1f;
  color: #d7b46a;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.photo-servicesContainer {
  display: flex;
  flex-direction: column;
}

.photo-serviceRow,
.photo-flexContainer {
  display: flex;
  justify-content: space-between;
  gap: 8.75rem;
}

.photo-serviceColumn {
  width: 45%;
  margin-top: 3rem;
}

.photo-serviceContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.photo-serviceIcon {
  width: 4.625rem;
  height: 4.625rem;
  object-fit: contain;
}

.photo-serviceTitle {
  font-size: 1.5rem;
  margin-top: 0.938rem;
}

.photo-serviceDescription {
  margin-top: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.serviceIcon {
  width: 6.25rem;
  height: auto;
  filter: grayscale(0%);
  transition: filter 0.3s ease;
}

.serviceIcon.activeIcon {
  filter: grayscale(100%);
}

/* Contenidor principal de l'experiència */
.experience-container {
  background-color: #1f1f1f;
  padding: 2.5rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #d7b46a;
  padding-bottom: 5em;
}

/* Títol */
.experience-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 3rem;
  margin-bottom: 1.25rem;
  text-align: center;
}

/* Contenidor del carrusel */
.carousel-container {
  width: 100%;
  max-width: 75rem;
  max-height: 56.25rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Imatges del carrusel */
.carousel-image {
  width: 100%;
  max-width: 50rem;
  height: auto;
  object-fit: cover;
  max-height: 43.75rem;
  border-radius: 8px;
  margin-right: 1.25rem;
  transition: transform 0.5s ease;
  margin-left: 2.5%;
}

/* Botons de navegació */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  padding: 0.625rem;
  cursor: pointer;
  z-index: 2;
}

.carousel-button.left {
  left: 0;
}

.carousel-button.right {
  right: 0;
}

/* Responsivitat */
@media (max-width: 768px) {
  .servicesIcons,
  .photo-explanation,
  .carousel-container {
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1.25rem;
  }

  .photo-leftColumn,
  .photo-rightColumn,
  .photo-serviceColumn,
  .photo-serviceRow {
    width: 100%;
  }

  .experience-title {
    font-size: 1.5rem;
  }

  .carousel-image {
    max-width: 100%;
    margin: 0;
  }

  .carousel-button {
    padding: 0.5rem;
  }
}

/*------------------------------ CONTACT FORM -----------------------------------------------------*/

.contact-container {
  position: relative;
  background-image: url("../src/assets/freelance.jpg");
  background-size: cover;
  background-position: center;
  padding: 4.375rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(201, 198, 198, 0.7);
  z-index: 1;
}

.contact-wrapper {
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 31.25rem;
  width: 100%;
  margin-left: auto;
  margin-right: 10%;
}

.contact-title {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 300;
  color: #333;
  font-size: 1.75rem;
  margin-bottom: 1.25rem;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.contact-form input,
.contact-form textarea {
  padding: 0.938rem;
  font-size: 0.875rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form textarea {
  resize: none;
  height: 9.375rem;
}

.contact-form button {
  padding: 0.938rem;
  font-size: 0.875rem;
  background-color: #1e1f1f;
  color: #d7b46a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background: #d7b46a;
  color: #3a3a3a;
}

/* Responsivitat */
@media (max-width: 768px) {
  .contact-wrapper {
    padding: 2rem;
    margin: 0 1rem;
  }

  .contact-title {
    font-size: 1.5rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.813rem;
  }

  .contact-form button {
    font-size: 0.813rem;
  }
}

/*-------------------------------  WebFrontEnd ---------------------------------------------------*/
.frontend-section {
  position: relative;
  height: 50vh;
  width: 100%;
  overflow: hidden;
}

.frontend-section .hero-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: url("../src/assets/website.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.frontend-section .hero-image::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.content-wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  color: #d7b46a;
  z-index: 2;
}

.title {
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: -0.05em;
}

.description {
  font-size: 1.063rem;
  letter-spacing: -0.26px;
  margin-top: 1.25rem;
}

.call-to-action {
  margin-top: 2.5rem;
}

button {
  background: #1e1f1f;
  color: #d7b46a;
  padding: 0.75rem 2.875rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #d7b46a;
  color: #3a3a3a;
}

/* Responsivitat */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .call-to-action {
    margin-top: 1.5rem;
  }

  button {
    padding: 0.625rem 2rem;
    font-size: 0.875rem;
  }
}

/* ----------------------------   Motion Graphic ---------------------------------------------------*/
.motion-section {
  position: relative;
  height: 50vh;
  width: 100%;
  overflow: hidden;
}

.motion-section .hero-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: url("../src/assets/motion.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.motion-section .hero-image::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.content-wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  color: #d7b46a;
  z-index: 2;
}

.title {
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: -0.05em;
}

.description {
  font-size: 1.063rem;
  letter-spacing: -0.26px;
  margin-top: 1.25rem;
}

.call-to-action {
  margin-top: 2.5rem;
}

button {
  background: #1f1f1f;
  color: #d7b46a;
  padding: 0.75rem 2.875rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #d7b46a;
  color: #3a3a3a;
}


/* --------------------------------- AUTOMATION DESIGN ----------------------------------- */

/* Secció hero per a Automation Design */
.automation-section {
  position: relative;
  height: 50vh;
  width: 100%;
  overflow: hidden;
}

.automation-section .hero-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: url("../src/assets/automation.jpg");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.automation-section .hero-image::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/* Contingut textual superposat */
.automation-section .content-wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  color: #d7b46a;
  z-index: 2;
}

/* Contenidor per al vídeo */
.video-automation-container {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center; 
  align-items: center;
}

.automation-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  outline: none;
  border: none;
}


/* ---------------------------- PhotoCorporatePortraits Responsivitat ---------------------------- */



@media (max-width: 320px) {
  .photo-explanation {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .photo-leftColumn,
  .photo-rightColumn {
    width: 100%;
    text-align: center;
  }

  .photo-title {
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  .photo-description {
    margin-top: 0.75rem;
    line-height: 1.4;
    font-size: 0.83rem;
    padding: 0 1rem;
    text-align: justify;
  }

  .photo-servicesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }

  .photo-serviceRow,
  .photo-flexContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .photo-serviceColumn {
    width: 100%;
    margin-top: 1rem;
  }

  .photo-serviceContent {
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .photo-serviceDescription {
    text-align: center; 
    margin: 0.5rem 0; 
  }
  
  .photo-serviceIcon {
    width: 3rem;
    height: 3rem;
  }

  .photo-serviceTitle {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }

  .photo-serviceDescription {
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }

  .feather-smile {
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
  }
  
  .description-section {
    padding:0;
    width:100%;
    margin:auto;
    text-align: center;
    margin-top:0;
    padding-bottom: 2rem;
  }
}

  /* Regles per 375px */

@media (max-width: 375px) {
  .photo-explanation {
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
  }

  .photo-leftColumn,
  .photo-rightColumn {
    width: 100%;
    text-align: center;
  }

  .photo-title {
    font-size: 1.75rem;
    padding-top: 1rem;
  }

  .photo-description {
    margin-top: 1rem;
    line-height: 1.5;
    font-size: 0.81rem;
    padding: 0 1.5rem;
    text-align: justify;
  }

  .photo-servicesContainer {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 0 1.5rem;
  }

  .photo-serviceRow,
  .photo-flexContainer {
    flex-direction: column;
    gap: 1.25rem;
  }

  .photo-serviceColumn {
    width: 100%;
    margin-top: 1.5rem;
  }

  .photo-serviceContent {
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
  .photo-serviceDescription {
    text-align: center;
    margin: 0.5rem 0; 
  }
  

  .photo-serviceIcon {
    width: 3.5rem;
    height: 3.5rem;
  }

  .photo-serviceTitle {
    font-size: 1.5rem;
    margin-top: 0.75rem;
  }

  .photo-serviceDescription {
    font-size: 0.85rem;
    margin-top: 0.75rem;
  }

  .feather-smile {
    width: 22px;
    height: 22px;
    margin-left: 0.5rem;
  }

  .description-text {
    padding:0;
    width:100%;
    margin:auto;
    text-align: center;
    font-size: 0.875rem;
    margin-top:0;
  }

}


/* Responsivitat per a mòbils */
@media (max-width: 480px) {
  .photo-explanation {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .photo-leftColumn,
  .photo-rightColumn {
    width: 100%;
    text-align: center;
  }

  .photo-title {
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  .photo-description {
    margin-top: 0.75rem;
    line-height: 1.4;
    font-size: 0.9rem;
    padding: 0 1rem;
    text-align: justify;
  }

  .photo-servicesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }

  .photo-serviceRow,
  .photo-flexContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .photo-serviceColumn {
    width: 100%;
    margin-top: 1rem;
  }

  .photo-serviceContent {
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .photo-serviceIcon {
    width: 3rem;
    height: 3rem;
  }

  .photo-serviceTitle {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }

  .photo-serviceDescription {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    text-align: center;
  }

  .description-text {
    padding:0;
    width:90%;
    margin:auto;
    text-align: center;
    font-size: 0.875rem;
    margin-top:0;
  }

  .experience-title {
    font-size: 1.25rem;
  }

  .carousel-image {
    max-width: 100%;
    margin: 0;
  }

  .contact-wrapper {
    padding: 1.5rem;
    margin: 0 1rem;
  }

  .contact-title {
    font-size: 1.25rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.75rem;
  }

  .contact-form button {
    font-size: 0.75rem;
  }

  .title {
    font-size: 1.75rem;
  }

  .description {
    font-size: 0.85rem;
  }

  .call-to-action {
    margin-top: 1rem;
  }

  button {
    padding: 0.5rem 1.5rem;
    font-size: 0.75rem;
  }

  .image-gallery {
    padding: 2rem 1rem;
  }

  .carousel-button {
    padding: 0.3rem;
  }

  .servicesIcons {
    gap: 2rem;
  }

  .serviceIcon {
    width: 3rem;
  }

  .serviceTitle {
    font-size: 1rem;
    margin-top: 0.5rem;
    color:#d7b46a;
  }

  .experience-container {
    padding-bottom: 2.5em;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  /* PhotoCorporatePortraits */
  .photo-explanation {
    display: flex;
    justify-content: space-between; 
    flex-wrap: wrap; 
    padding: 1rem 2rem; 
    gap: 2rem; 
  }
  
  .photo-flexContainer {
    display: flex;
    flex-direction: row;
    gap: 2rem; 
  }
  
  .photo-leftColumn {
    flex: 1 1 30%; 
    max-width: 30%; 
    text-align: left;
  }
  
  .photo-rightColumn {
    flex: 1 1 65%;
    max-width: 65%; 
  }
  
  .photo-contentWrapper {
    padding: 0.5rem; 
  }
  
  .photo-title {
    font-size: 2rem; 
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .photo-description {
    font-size: 0.9rem; 
    line-height: 1.4; 
    margin-bottom: 1.5rem;
    text-align: justify; 
  }
  

  /* Adjustments for .servicesIcons */
  .servicesIcons {
    flex-direction: row;
    justify-content: space-around;
    gap: 1rem;
    padding: 2rem 1rem;
    flex-wrap: wrap;
  }

  .serviceItem {
    flex: 1;
    max-width: 120px; 
    color:#d7b46a;
  }

  .serviceIcon {
    width: 3rem;
    height: 3rem;
  }
}


@media (min-width: 769px) and (max-width: 1024px) {
  /* PhotoCorporatePortraits */
  .photo-explanation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.5rem 3rem;
    gap: 2rem;
  }

  .photo-flexContainer {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  .photo-leftColumn {
    flex: 1 1 35%;
    max-width: 35%;
    text-align: left;
  }

  .photo-rightColumn {
    flex: 1 1 60%;
    max-width: 60%;
  }

  .photo-contentWrapper {
    padding: 1rem;
  }

  .photo-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .photo-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    text-align: justify;
  }

  /* Adjustments for .servicesIcons */
  .servicesIcons {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    gap: 4rem;
    padding: 2rem 1rem;
    flex-wrap: wrap;
  }
  
  .serviceItem {
    flex: 0 1 auto; 
    max-width: 520px;
    text-align: center;
    font-size: 14px;
    color:#d7b46a;
  }
  
  .serviceIcon {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.5rem;
  }
  
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .photo-explanation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem 5rem;
    gap: 2.5rem;
  }

  .photo-leftColumn {
    flex: 1 1 30%;
    max-width: 30%;
    text-align: left;
  }

  .photo-rightColumn {
    flex: 1 1 65%;
    max-width: 65%;
  }

  .photo-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .photo-description {
    font-size: 1.125rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .servicesIcons {
    display: flex;
    justify-content: center;
    gap: 6rem;
    padding: 2.5rem 1rem;
    flex-wrap: wrap;
  }

  .serviceItem {
    max-width: 150px;
    text-align: center;
    color: #d7b46a;
  }

  .serviceIcon {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
  }

  .contact-wrapper {
    padding: 3rem;
    margin: 0 auto;
    max-width: 40rem;
  }

  .contact-title {
    font-size: 2rem;
  }

  .carousel-image {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 1921px) {
  .menu-container {
    padding: 1.5rem 7%;
  }

  .logo {
    max-width: 150px;
  }

  .menu-items {
    gap: 2rem;
    font-size: 1rem;
  }

  .servicesIcons{
    padding-right:6rem;
    padding-left:6rem;
    gap:10rem;
  }
 
}

@media (min-width: 2561px) {

  .content-wrapper, .title{
    font-size: 4rem;
  }

  .content-wrapper,.description{
    font-size: 1.5rem;
  }

  .menu-container {
    padding: 2rem 10%;
  }

  .logo {
    max-width: 200px;
  }

  .menu-items {
    gap: 3rem;
    font-size: 1.2rem;
  }


  .photo-description {
    font-size: 1.1rem;
  }

  .photo-serviceDescription{
    font-size: 1rem;
  }
}


/* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .contact-wrapper {
    max-width: 50rem;
    padding: 4rem;
    border-radius: 10px;
  }

  .contact-title {
    font-size: 2.5rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1.2rem;
    padding: 1rem;
  }

  .contact-form button {
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
  .contact-wrapper {
    max-width: 60rem;
    padding: 5rem;
    border-radius: 12px;
  }

  .contact-title {
    font-size: 3rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1.5rem;
    padding: 1.5rem;
  }

  .contact-form button {
    font-size: 1.5rem;
    padding: 1.5rem 3rem;
    border-radius: 8px;
  }
}


