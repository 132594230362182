/* Assegura que tots els elements utilitzin box-sizing: border-box */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Estils generals */ 
body,
html {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #ececec;
  color: #333;
  overflow-x: hidden;
}

/* Contenidor de pàgina */
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Estil general per als enllaços */
a {
  color: #d7b46a;
  text-decoration: none;
}

a:hover {
  color: #ececec;
  transition: color 0.3s ease;
}

a:active {
  color: #b5974f;
}


/* Nav bar (container principal del menú) */
.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: #f4f4f4;
  position: relative;
  z-index: 10;
  width: 100%; 
  box-sizing: border-box;
}

.logo {
  max-width: 100px; /* Limita la mida del logo */
  height: auto;
  margin: 0 auto; /* Centra el logo */
}

.menu-items {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  margin-right: 1rem;
  flex-wrap: wrap; 
  justify-content: space-between;
  font-size: 0.8rem;
  white-space: nowrap; 
}

/* Icones login / cart */
.logIn,
.shopping-cart {
  width: 1.5rem;
  height: auto;
  cursor: pointer;
}

/* --- D R O P D O W N   S E R V I C E S  --- */
.dropdown {
  position: relative;
}
.dropdown-content {
  position: absolute;
  top: calc(100% + 0.4rem); 
  left: 0;                 
  background-color: white;
  min-width: 160px;
  padding: 0.5rem 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-radius: 4px;
}
.dropdown-content a {
  color: #333;
  padding: 0.75rem 1rem;
  text-decoration: none;
  display: block;
  transition: background 0.3s ease;
}
.dropdown-content a:hover {
  background-color: #ececec;
  color: #d7b46a;
}

/* --- D R O P D O W N   U S E R  --- */
.user-menu {
  position: relative; 
  cursor: pointer;
}
.user-dropdown {
  position: absolute;
  top: calc(100% + 0.4rem);
  right: 0; 
  background-color: white;
  min-width: 160px;
  padding: 0.5rem 0;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 9999; 
  border-radius: 4px;
  cursor: pointer;
}
.user-dropdown .dropdown-item {
  color: #333;
  padding: 0.75rem 1rem;
  text-decoration: none;
  display: block;
  transition: background 0.3s ease;
}
.user-dropdown .dropdown-item:hover {
  background-color: #ececec;
  color: #d7b46a;
}

/* Menu item (enllaços nav) */
.menu-item {
  padding: 0px 1rem;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  transition: color 0.3s ease;
  cursor: pointer;
}
.menu-item:hover {
  color: #d7b46a;
}

/* ========== Footer i la resta ========== */
.footer {
  flex-shrink: 0;
  padding: 2.5rem 0;
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 100%;
  background-color: #252423;
  color: #d7b46a;
  overflow: hidden;
  margin-top: auto; 
}

/* Escopir les regles del footer per evitar conflictes */
.footer .footerWidth {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  width: 100%;
  padding: 0 5%;
  max-width: 1800px; 
  margin: 0 auto;
  box-sizing: border-box;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: flex;
  align-items: flex-start;
}

/* Secció esquerra del footer */
.footer .footer-left {
  flex: 0 0 auto; 
  flex-direction: column; 
}

/* Secció central del footer */
.footer .footer-center {
  flex: 1 1 auto; 
  justify-content: center; 
  text-align: center;
}

/* Secció dreta del footer */
.footer .footer-right {
  flex: 0 0 auto; 
  margin-left: auto; 
  text-align: right;
  flex-direction: column; 
}

/* Text de contacte dins de la secció dreta */
.footer .contact-text {
  font-size: 0.875rem;
  text-align: right;
  margin-top: 1.4rem;
}

.footer .contact-email {
  color: #d7b46a;
  text-decoration: none;
  font-size: 0.875rem;
}

/* Logo del footer */
.footer .footer-logo {
  width: 5.5rem;
  margin-bottom: 1.25rem;
}

/* Icones socials */
.footer .social-icons {
  display: flex;
  gap: 1.25rem;
}

.footer .social-icon {
  width: 2rem;
  height: auto;
}

.footer-black {
  background-color: #252423;
  color: #d7b46a;
}

.footer-white {
  background-color: #ececec;
  color: #272525;
}

/* ============== R E S P O N S I V E ============== */

/* Fins a 320px */
@media (max-width: 320px) {
  .menu-items {
    flex-direction: row; 
    white-space: nowrap; 
    overflow-x: auto; 
    overflow-y: visible !important; 
    margin-top: 0.5rem;
    gap: 0.2rem;
    z-index: 999;
  }
  .menu-item {
    font-size: 0.7rem; 
    padding: 0 0.3rem;
  }
  .logo {
    max-width: 75px;
  }
  .logIn,
  .shopping-cart {
    width: 1rem;
  }
  .footer {
    padding: 1rem 0;
    flex-direction: column;
    align-items: center;
  }
  
  .footer .footerWidth {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;
    padding: 0 2%;
    width: 100%;
    max-width: 100%;
  }
  
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    flex: none;
    width: 100%;
    text-align: center;
    margin: 0.5rem 0;
    align-items: center; 
    display: flex;
    flex-direction: column; 
  }
  
  
  .footer .footer-center {
    justify-content: center;
  }
  
  
  .footer .contact-text {
    text-align: center;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .footer .social-icons {
    gap: 1rem;
    justify-content: center;
    padding: 0 1rem;
  }
  
  .footer .footer-logo {
    width: 4rem;
    margin-bottom: 0.75rem;
  }
  
  .footer .social-icon {
    width: 1.5rem;
  }
  
  /* Assegura que el logo es centrat */
  .footer-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-container {
    padding: 0.5rem 2%;
  }
}

/* Fins a 375px */
@media (max-width: 375px) {
  .menu-items {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: visible !important;
    gap: 0.5rem;
    margin-top: 0.5rem;
    z-index: 999; 
  }
  .logIn, .shopping-cart {
    width: 1rem;
  }
  .menu-item {
    font-size: 0.75rem;
    padding: 0 0.5rem;
  }
  .logo {
    max-width: 85px;
  }
  .footer {
    padding: 1rem;
    flex-direction: column;
    align-items: center;
  }
  
  .footer .footerWidth {
    width: 100%;
    padding: 0 2%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    text-align: center;
    margin: 0.5rem 0;
    flex: none;
    width: 100%;
    align-items: center; 
    display: flex;
    flex-direction: column; 
  }
  
  .footer .footer-center {
    justify-content: center;
  }
  
  .footer .footer-logo {
    width: 4rem;
    margin-bottom: 0.75rem;
  }
  
  .footer .contact-text {
    font-size: 0.75rem;
    text-align: center;
  }
  
  .footer .social-icons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 0 1rem;
  }
  
  .footer .social-icon {
    width: 1.5rem;
  }
  
  .footer .contact-email {
    font-size: 0.875rem; 
  }
  
  .footer-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* Fins a 480px */
@media (max-width: 480px) {
  .menu-container {
    padding: 1rem;
    flex-direction: column;
    align-items: center;
  }
  .menu-items {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    overflow: visible;
  }
  .footer {
    padding: 1rem;
    flex-direction: column;
    align-items: center;
  }
  
  .footer .footerWidth {
    width: 100%;
    padding: 0 2%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    text-align: center;
    margin: 0.5rem 0;
    flex: none;
    width: 100%;
    align-items: center; 
    display: flex;
    flex-direction: column; 
  }
  
  .footer .footer-center {
    justify-content: center;
  }
  
  .footer .footer-logo {
    width: 4rem;
    margin-bottom: 0.75rem;
  }
  
  .footer .contact-text {
    font-size: 0.75rem;
    text-align: center;
  }
  
  .footer .social-icons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 0 1rem;
  }
  
  .footer .social-icon {
    width: 1.5rem;
  }
  
  .footer .contact-email {
    font-size: 0.875rem; 
  }
  
  .footer-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Media query per a pantalles de 1921px fins a 2560px */
@media (min-width: 1921px) {
  body,
  html {
    font-size: 1.4rem; 
    line-height: 1.6;
  }

  .container {
    max-width: 85%;
    padding: 0 2.5rem;
  }

  .menu-container {
    padding: 1.25rem 6%;
    font-size: 1rem;
  }

  .logo {
    max-width: 110px; 
  }

  .menu-items {
    gap: 1.5rem;
    font-size: 0.9rem;
  }

  .logIn,
  .shopping-cart {
    width: 1.75rem;
  }

  .footer {
    padding: 2.75rem 0;
    font-size: 1rem;
  }

  .footer-logo {
    width: 6.5rem;
  }

  .social-icons {
    gap: 1.75rem;
  }

  .social-icon {
    width: 2.25rem;
  }

  .contact-text {
    font-size: 0.95rem;
  }
}


/* Media query per a pantalles grans de 2561px i superiors */
@media (min-width: 2561px) {
  body,
  html {
    font-size: 1.5rem; 
    line-height: 1.6;
  }

  .container {
    max-width: 90%;
    padding: 0 3rem;
  }

  .menu-container {
    padding: 1.5rem 8%;
    font-size: 1.1rem;
  }

  .logo {
    max-width: 120px; 
  }

  .menu-items {
    gap: 2rem;
    font-size: 1rem;
  }

  .logIn,
  .shopping-cart {
    width: 2rem;
  }

  .footer {
    padding: 3rem 0;
    font-size: 1.1rem;
  }

  .footer-logo {
    width: 7rem;
  }

  .social-icons {
    gap: 2rem;
  }

  .social-icon {
    width: 2.5rem;
  }

  .contact-text {
    font-size: 1rem;
  }

  .contact-email {
    font-size: 1.1rem;
  }
}



