/*--------------------------------- signe marca registrada ---------------------------------------*/
.reg-symbol {
  font-size: 0.8em;
  color: #d7b46a;
  vertical-align: super;
}

/*-------------------------------- AboutSection ---------------------------------------------*/
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;
  color: #d7b46a;
  width: 100%;
}

.content-container {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Dues columnes, 2:1 */
  gap: 20rem;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.about-description-wrapper {
  padding-right: 1rem;
}

.about-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 3.3em;
  margin-bottom: 1rem;
}

.about-description,
.about-maintext,
.about-secondarytext {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  text-align: justify;
}

/*---- As a freelance...--------*/
.about-secondarytext {
  text-align: center;
  width: 90%;
  margin: auto;
  line-height: 1.6;
}
/*------------------------------*/
.quote {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2em;
  font-style: italic;
  color: #d7b46a;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.services-wrapper h3 {
  margin-top: 3rem;
  font-size: 1.5em;
  margin-bottom: 0.5rem;
}

.services-list,
.software-list,
.social-list {
  list-style: none;
  padding: 0;
  font-size: 1em;
  line-height: 1;
}

.services-list li,
.software-list li,
.social-list li {
  margin-bottom: 0.5rem;
}

/* Generative Art Section */
.generative-art-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  background-color: #252423;
  overflow: hidden;
}

.generative-art-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.generative-art-overlay {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px 100px;
  border-radius: 10px;
}

.generative-art-title {
  font-size: 3rem;
  font-family: "Bebas Neue", sans-serif;
  color: #d7b46a;
  text-align: center;
}

.generative-art-title a {
  text-decoration: none;
  color: #d7b46a;
}

.generative-art-title a:hover {
  color: #ececec;
  transition: color 0.3s ease;
}

@media (max-width: 320px) {
  .content-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  .about-description-wrapper {
    width: 100%;
    padding: 0 1rem;
    text-align: center;
  }

  .about-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .about-description,
  .about-maintext,
  .about-secondarytext {
    font-size: 0.75rem;
    padding: 0 1rem;
    text-align: justify;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }

  .about-secondarytext {
    text-align: center;
  }

  .quote {
    width: 98%;
    margin: auto;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .services-wrapper {
    width: 100%;
    padding: 0 1rem;
    text-align: center;
  }

  .services-wrapper h3 {
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .services-list,
  .software-list,
  .social-list {
    font-size: 0.65rem;
    padding: 0 1rem;
  }

  .services-list li,
  .software-list li,
  .social-list li {
    margin-bottom: 0.5rem;
  }

  .generative-art-section {
    height: 40vh;
    width: 100%;
  }

  .generative-art-video {
    width: 100%;
    height: 100%;
  }

  .generative-art-overlay {
    padding: 15px 20px;
  }

  .generative-art-title {
    font-size: 1.5rem;
  }

  .reg-symbol {
    font-size: 0.8em;
    color: #d7b46a;
    vertical-align: super;
  }

  .section-wrapper {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 0.75rem;
  }
}


@media (max-width: 375px) {
  .content-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  .about-description-wrapper {
    width: 100%;
    padding: 0 1rem;
    text-align: center;
  }

  .about-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .about-description,
  .about-maintext,
  .about-secondarytext {
    font-size: 0.75rem;
    padding: 0 1rem;
    text-align: justify;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }

  .about-secondarytext {
    text-align: center;
  }

  .quote {
    width: 98%;
    margin: auto;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .services-wrapper {
    width: 100%;
    padding: 0 1rem;
    text-align: center;
  }

  .services-wrapper h3 {
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .services-list,
  .software-list,
  .social-list {
    font-size: 0.65rem;
    padding: 0 1rem;
  }

  .services-list li,
  .software-list li,
  .social-list li {
    margin-bottom: 0.5rem;
  }

  .generative-art-section {
    height: 40vh;
    width: 100%;
  }

  .generative-art-video {
    width: 100%;
    height: 100%;
  }

  .generative-art-overlay {
    padding: 15px 20px;
  }

  .generative-art-title {
    font-size: 1.5rem;
  }

  .reg-symbol {
    font-size: 0.8em;
    color: #d7b46a;
    vertical-align: super;
  }

  .section-wrapper {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 0.75rem;
  }
}


@media (max-width: 480px) {
  .content-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .about-description-wrapper {
    padding-right: 0;
    text-align: center;
  }

  .about-title {
    font-size: 2.5rem;
  }

  .about-description,
  .about-maintext,
  .about-secondarytext {
    font-size: 0.875rem;
    padding: 0 1rem;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }

  .quote {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .services-wrapper {
    width: 100%;
    padding: 0 1rem;
    text-align: center;
  }

  .services-wrapper h3 {
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .services-list,
  .software-list,
  .social-list {
    font-size: 0.75rem;
    padding: 0 1rem;
  }

  .services-list li,
  .software-list li,
  .social-list li {
    margin-bottom: 0.5rem;
  }

  .generative-art-section {
    height: 40vh;
  }

  .generative-art-overlay {
    padding: 15px 20px;
  }

  .generative-art-title {
    font-size: 1.5rem;
  }

  .feather-smile {
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
  }

  .section-wrapper {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 0.875rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .content-container {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1rem;
    max-width: 100%;
    margin: 0 auto;
    width: 90%;
  }

  .services-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    padding: 1rem;
    width: 100%; 
    margin:auto;
    background-color:#2c2b2a;
    border-radius: 2%;
  }

  .services-wrapper h3 {
    font-size: 1em;
    margin-top:0;
    margin-bottom: 0;
    text-align: center;
  }

  .services-list,
  .software-list,
  .social-list {
    font-size: 0.875rem;
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 30%; 
  }

  .services-list li,
  .software-list li,
  .social-list li {
    text-align: left;
    font-size: 0.9rem;
  }

  .generative-art-section {
    height: 50vh;
    width: 100%;
  }

  .generative-art-video {
    width: 100%;
    height: 100%;
  }

  .generative-art-overlay {
    padding: 20px 50px;
  }

  .generative-art-title {
    font-size: 2rem;
  }

  .about-title {
    font-size: 2.5em;
  }

  .about-description,
  .about-maintext,
  .about-secondarytext {
    font-size: 0.875rem;
  }

  .quote {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .content-container{
     padding: 1rem 4rem;
    }

    .about-description-wrapper{
      width:140%;
      padding-left:6rem;
    }

    .services-wrapper{
      width:100%;
      margin-left:0rem;
    }
  }


  /* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .content-container{
    padding: 0;
   }

   .about-description-wrapper{
     width:150%;
     padding-left:0rem;
   }

   .services-wrapper{
     width:160%;
     margin-left:1rem;
     margin-top:4rem;
     font-size: 1.3rem;
   }
   .quote {
    font-size: 2.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
   .services-wrapper{
     margin-left:4rem;
   }
   .quote {
    font-size: 2.8rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
