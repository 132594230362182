/* ---------------------------- UserProfile Section ---------------------------- */

.user-profile-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.profile-image-section {
  flex: 1;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info-section {
  flex: 1;
  background-color: #333;
  color: #d7b46a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0; 
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.profile-info-section h2 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 3rem;
  font-weight: 300;
  color: #d7b46a;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.profile-details {
  width: 100%;
  max-width: 500px;
  background-color: #1e1f1f;
  padding: 1.875rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  margin:0;
}

.info-section,
.address-section {
  margin-bottom: 1rem;
  color: #d7b46a;
  background-color: #333;
  line-height: 1.5rem; 
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.preferences-section {
  line-height: 1.5em;
  margin-top: 2rem;
  padding: 0.6rem 1.5rem;
  background-color: #333;
  color: #d7b46a;
  border-radius: 8px;
}

.info-section h3,
.address-section h3,
.preferences-section h3 {
  font-size: 1.2em;
  color: #d7b46a;
  margin-bottom: 0.5rem;
  padding-bottom: 0.2rem;
  text-decoration: underline;
  text-align: left;

}

.preferences-section label{
  text-align: left;
}

.address-section .edit-button {
  margin-bottom: 0.4rem;
}

.user-icon {
  width: 84px;
  height: 84px;
  margin-right: 1.5rem;
  margin-top: 0.4rem;
  margin-left:2rem;
}

.info-item {
  display: flex;
  align-items: center;
}

.info-text {
  display: flex;
  flex-direction: column;
  color: #d7b46a;
  margin-top: 0.6rem;
}

.info-text p {
  margin: 0.3rem 0.5rem;
  font-size: 0.875rem;
  color: #d7b46a;
  text-align: left;
}

.address-form {
  display: flex;
  flex-direction: column;
  gap: 0.4rem; 
  text-align: left;
}

.address-form label {
  margin-bottom: 5px;
  padding-top: 8px;
  font-weight: normal;
}

.address-form input {
  padding: 10px;
  background-color: #1e1f1f;
  color: #d7b46a;
  border: 1px solid #1e1f1f;
  border-radius: 4px;
  box-sizing: border-box;
}

.address-form input:focus {
  color: #d7b46a;
  outline: none;
  border-color: #d7b46a;
  background-color: #1e1f1f;
}

.address-form input:-webkit-autofill {
  background-color: #1e1f1f;
  color: #d7b46a;
  border: 1px solid #1e1f1f;
  -webkit-text-fill-color: #d7b46a;
  transition: background-color 5000s ease-in-out 0s;
}

.address-form input:autofill {
  background-color: #1e1f1f;
  color: #d7b46a;
  border: 1px solid #1e1f1f;
}

.edit-button {
  background-color: #d7b46a;
  color: #1e1f1f;
  border: none;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.edit-button:hover {
  background-color: #1e1f1f;
  color: #d7b46a;
}

.preferences-section label {
  font-size: 0.875rem;
  color: #d7b46a;
  margin-bottom: 0.5rem;
  display: block;
  margin-top: 0.3rem;
  font-weight: 300;
}

.preferences-section select {
  width: 100%;
  border: 1px solid #1b1b1b;
  border-radius: 5px;
  background-color: #1b1b1b;
  color: #d7b46a;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  padding-top: 0.8rem;
  font-weight: 300;
}

.preferences-section select:focus {
  outline: none;
  border-color: #d7b46a;
}

.update-status {
  font-size: 0.875rem;
  color: #d7b46a;
  text-align: center;
}

@media (max-width: 320px) {
  .user-profile-page {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .profile-image-section {
    width: 100%;
    height: 150px;
  }

  .profile-image {
    height: 100%;
  }

  .profile-info-section {
    width: 100%;
    padding: 0;
    margin: 0;
    align-items: stretch;
    text-align: left;
  }

  .section-title{
    margin-left:2rem;
  }

  .profile-info-section h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
    position: static;
    transform: none;
    top: auto;
    left: auto;
  }

  .profile-details {
    max-width: 100%;
    padding: 0.8rem;
    margin: 0;
  }

  .info-section,
  .address-section {
    text-align: left;
  }

  .info-section h3,
  .address-section h3,
  .preferences-section h3 {
    font-size: 1rem;
    text-align: left;
  }

  .preferences-section {
    text-align: left;
    font-size: 1rem;
  }

  .preferences-section label {
    font-size: 0.9rem;
  }

  .preferences-section select {
    font-size: 0.75rem;
    padding-top: 0.5rem;
  }

  .update-status {
    font-size: 0.7rem;
  }

  .info-text p{
    text-align: left;
    line-height: 1.2;
    font-size: 0.7rem;
  }

  .info-item img {
    max-width: 50px;
    margin-right: 10px;
    margin-left: 0;
  }

  .edit-button {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }

  .photo-explanation {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .photo-leftColumn,
  .photo-rightColumn {
    width: 100%;
    text-align: center;
  }

  .photo-title {
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  .photo-description {
    margin-top: 0.75rem;
    line-height: 1.4;
    font-size: 0.875rem;
    padding: 0 1rem;
    text-align: left;
  }

  .photo-servicesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }

  .photo-serviceRow,
  .photo-flexContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .photo-serviceColumn {
    width: 100%;
    margin-top: 1rem;
    text-align: left;
  }

  .photo-serviceContent {
    align-items: center;
    text-align: center;
  }

  .photo-serviceIcon {
    width: 3rem;
    height: 3rem;
  }

  .photo-serviceTitle {
    font-size: 1.25rem;
    margin-top: 0.5rem;
    text-align: left;
  }

  .photo-serviceDescription {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    text-align: left;
  }

  .feather-smile {
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
  }

  .description-text {
    padding: 0 1rem;
    font-size: 0.875rem;
    text-align: left;
  }
}


@media (max-width: 375px) {
  .user-profile-page {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .profile-image-section {
    width: 100%;
    height: auto;
  
  }

  .profile-image {
    height: 100%;
  }

  .profile-info-section {
    width: 100%;
    padding: 0;
    margin: 0;
    align-items: stretch;
    text-align: left;
  }

  .section-title{
    margin-left:2rem;
  }

  .profile-info-section h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
    position: static;
    transform: none;
    top: auto;
    left: auto;
  }

  .profile-details {
    max-width: 100%;
    padding: 0.8rem;
    margin: 0;
  }

  .info-section,
  .address-section {
    text-align: left;
  }

  .info-section h3,
  .address-section h3,
  .preferences-section h3 {
    font-size: 1rem;
    text-align: left;
  }

  .preferences-section {
    text-align: left;
    font-size: 1rem;
  }

  .preferences-section label {
    font-size: 0.9rem;
  }

  .preferences-section select {
    font-size: 0.75rem;
    padding-top: 0.5rem;
  }

  .update-status {
    font-size: 0.7rem;
  }

  .info-text {
    text-align: left;
    line-height: 1.2;
  }

  .info-item img {
    max-width: 60px;
    margin-right: 15px;
    margin-left: 0;
  }

  .edit-button {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }

  .photo-explanation {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .photo-leftColumn,
  .photo-rightColumn {
    width: 100%;
    text-align: center;
  }

  .photo-title {
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  .photo-description {
    margin-top: 0.75rem;
    line-height: 1.4;
    font-size: 0.875rem;
    padding: 0 1rem;
    text-align: left;
  }

  .photo-servicesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }

  .photo-serviceRow,
  .photo-flexContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .photo-serviceColumn {
    width: 100%;
    margin-top: 1rem;
    text-align: left;
  }

  .photo-serviceContent {
    align-items: flex-start;
    text-align: left;
  }

  .photo-serviceIcon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  .photo-serviceTitle {
    font-size: 1.1rem;
    margin-top: 0.4rem;
    text-align: left;
  }

  .photo-serviceDescription {
    font-size: 0.7rem;
    margin-top: 0.4rem;
    text-align: left;
  }

  .feather-smile {
    width: 18px;
    height: 18px;
    margin-left: 0.4rem;
  }

  .description-text {
    padding: 0 0.8rem;
    font-size: 0.8rem;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .user-profile-page {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .profile-image-section {
    width: 100%;
    height: auto;
  }

  .profile-image {
    width: 100%;
    height: auto;
  }

  .profile-info-section {
    width: 100%;
    padding: 1rem;
    margin: 0;
    align-items: stretch;
    text-align: left;
  }

  .section-title {
    margin-left: 1.5rem;
    font-size: 1.2rem;
  }

  .profile-info-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: left;
    position: static;
    transform: none;
    top: auto;
    left: auto;
  }

  .profile-details {
    max-width: 100%;
    padding: 1rem;
    margin: 0 0 1rem 0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  .info-section,
  .address-section {
    text-align: left;
    margin-bottom: 1rem;
  }

  .info-section h3,
  .address-section h3,
  .preferences-section h3 {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    text-align: left;
  }

  .preferences-section {
    text-align: left;
    font-size: 1rem;
    margin-top: 1rem;
    padding: 1rem;
  }

  .preferences-section label {
    font-size: 0.9rem;
    margin-bottom: 0.6rem;
    display: block;
  }

  .preferences-section select {
    font-size: 0.8rem;
    padding: 0.5rem;
    width: 100%;
  }

  .update-status {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 0.5rem;
  }

  .info-text {
    text-align: left;
    line-height: 1.4;
  }

  .info-item img {
    max-width: 70px;
    margin-right: 15px;
    margin-left: 0;
  }

  .edit-button {
    padding: 0.6rem;
    font-size: 0.9rem;
    width: 100%;
  }

  .preferences-section select {
    font-size: 0.8rem;
    padding: 0.6rem;
  }

  .description-text {
    padding: 0 1rem;
    font-size: 0.875rem;
    text-align: left;
  }

  .feather-smile {
    width: 18px;
    height: 18px;
    margin-left: 0.4rem;
  }
}

/* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .profile-info-section {
    padding: 4rem 2rem;
    font-size: 1.25rem;
  }

  .profile-info-section h2 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  .profile-details {
    max-width: 800px;
    padding: 2.5rem;
    font-size: 1.2rem;
  }

  .info-section,
  .address-section {
    padding: 2rem;
    font-size: 1.2rem;
  }

  .preferences-section {
    padding: 2rem;
    font-size: 1.1rem;
  }

  .edit-button {
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
  .profile-info-section {
    padding: 6rem 3rem;
    font-size: 1.5rem;
  }

  .profile-info-section h2 {
    font-size: 5rem;
    margin-bottom: 3rem;
  }

  .profile-details {
    max-width: 1000px;
    padding: 3rem;
    font-size: 1.5rem;
  }

  .info-section,
  .address-section {
    padding: 2.5rem;
    font-size: 1.4rem;
  }

  .preferences-section {
    padding: 2.5rem;
    font-size: 1.3rem;
  }

  .edit-button {
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
  }

  .update-status {
    font-size: 1.3rem;
  }
}
