/* -------------------------- Choose Artwork Section -------------------------- */

.choose-your-artwork-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  background-color: #ececec;
  min-height: calc(
    100vh - 10rem
  ); 
  flex-grow: 1; 
}

.choose-your-artwork-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 22rem;
}

/* Contenidor principal per a la imatge i les opcions */
.artwork-layout {
  display: grid;
  grid-template-columns: 2fr 1fr; 
  gap: 3rem; 
  max-width: 1200px;
  margin: 0 auto;
  align-items: start;
}

/* Estils per a la imatge */
.artwork-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artwork-image {
  max-width: 100%;
  height: auto;
  border: 5px solid #d7b46a;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

/* Estils per a les opcions */
.artwork-options {
  background-color: white;
  padding: 1.25rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.artwork-options label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.artwork-options select,
.artwork-options input {
  margin-bottom: 1.25rem;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  height: 2.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.total-price {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0;
  text-align: center;
}

.add-to-cart-button {
  background-color: #d7b46a;
  color: #1f1f1f;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.625rem 1.875rem;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  text-align: center;
}

.add-to-cart-button:hover {
  background-color: #b6953c;
  transition: background-color 0.3s ease;
}

.variant-thumbnail-preview {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.variant-thumbnail {
  width: 15rem;
  height: auto;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.2s;
}

.variant-thumbnail:hover {
  border-color: #d7b46a;
  transform: scale(1.1);
}

/*------------------------- modal ThisWasAdded ---------------------------------------------------*/
/* Overlay del modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

/* Finestra del modal */
.modal-content {
  position: absolute;
  top: 10vh; 
  bottom: 10vh; 
  max-height: 70vh; 
  padding: 5px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  z-index: 2000;
  overflow-y: auto; 
}

/* Header del modal */
.modal-content h2 {
  font-size: 1.1rem;
  margin: 0;
  text-align: left;
  padding-bottom: 1rem;
  padding-top: 2.2rem;
}

.close-button {
  position: absolute;
  top: 0.1rem;
  right: 2rem;
  background-color: #d7b46a;
  border: none;
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button:hover {
  color: #d7b46a;
  background-color: #333;
}

/* Imatge del modal */
.artwork-preview {
  width: 100%;
  height: auto;
  padding-top: 10px;
  margin-bottom: 5px;
  margin-top: 0;
  border-radius: 8px;
}

/* Detalls de la comanda */
.modal-content p {
  font-size: 0.9rem;
  margin: 5px 0;
  text-align: right;
}

.modal-content h3 {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0;
  text-align: right;
  padding-top: 10px;
}

.checkout-button {
  display: block;
  margin: 2rem auto;
  padding: 0.75rem 1.5rem;
  background-color: #1f1f1f;
  color: #d7b46a;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #d7b46a;
}
/* Adaptació per a tablets */
@media (max-width: 768px) {
  .modal-content {
    max-width: 280px;
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 1.1rem;
  }

  .buy-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

/* Adaptació per a mòbils */
@media (max-width: 480px) {
  .modal-content {
    max-width: 240px;
    padding: 10px;
  }

  .modal-content h2 {
    font-size: 1rem;
  }

  .buy-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}

/* Responsivitat */
@media (max-width: 768px) {
  .artwork-layout {
    grid-template-columns: 1fr; 
    gap: 1rem;
  }

  .artwork-details {
    text-align: center;
  }
}


/* Responsivitat per a 320px */
@media (max-width: 320px) {
  .choose-your-artwork-container {
    padding: 1rem;
    text-align: center;
    padding-top:0;
  }
  
    .choose-your-artwork-container h2 {
    font-size: 1.5rem;
    margin-top:0;
    margin-bottom: 1rem;
    margin-left: 0;
    text-align: center;
  }


  .artwork-layout {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .artwork-details img {
    max-width: 100%;
    height: auto;
  }

  .artwork-options {
    text-align: center;
    padding: 0 1rem;
  }

  .artwork-options label {
    font-size: 0.875rem;
    display: block;
    margin-bottom: 0.5rem;
    margin-top:0.8rem;
  }

  .artwork-options select,
  .artwork-options input {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 0.72rem;
    text-align: center;
  }


  .variant-thumbnail-preview img {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
  }

  .total-price h3 {
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .add-to-cart-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
  }

  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 90%;
    text-align: center;
  }

  .modal-content h2 {
    font-size: 1rem;
    margin-top:1.5rem;
    margin-bottom: 0;
  }

  .artwork-preview {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  .modal-content .close-button{
    font-size: 1rem;
    padding:0rem;
  }

 
  .checkout-button {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    font-size: 1rem;
  }
}

/* Responsivitat per a 375px */
@media (max-width: 375px) {
  .choose-your-artwork-container {
    padding: 1.5rem;
    text-align: center;
    padding-top: 0.5rem;
  }

  .choose-your-artwork-container h2 {
    font-size: 1.75rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
    margin-left: 0;
    text-align: center;
  }

  .artwork-layout {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .artwork-details img {
    max-width: 100%;
    height: auto;
    border-radius: 8px; 
  }

  .artwork-options {
    text-align: center;
    padding: 0 1.5rem;
  }

  .artwork-options label {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 0.75rem;
    margin-top: 1rem;
  }

  .artwork-options select,
  .artwork-options input {
    width: 100%;
    margin-bottom: 1.25rem;
    font-size: 0.8rem;
    text-align: center;
  }

  .variant-thumbnail-preview img {
    max-width: 100%;
    height: auto;
    margin-top: 1.5rem;
  }

  .total-price h3 {
    font-size: 1.5rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .add-to-cart-button {
    width: 100%;
    padding: 1rem;
    font-size: 1.1rem;
  }

  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 90%;
    text-align: center;
  }

  .modal-content h2 {
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .artwork-preview {
    max-width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
  }

  .modal-content .close-button {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .checkout-button {
    width: 100%;
    padding: 1rem;
    margin-top: 1.5rem;
    font-size: 1.1rem;
  }
}

/* Responsivitat per a 480px */
@media (max-width: 480px) {
  .choose-your-artwork-container {
    padding: 1.5rem;
    text-align: center;
    padding-top: 0.5rem;
  }

  .choose-your-artwork-container h2 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 0;
    text-align: center;
  }

  .artwork-layout {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .artwork-details img {
    max-width: 100%;
    height: auto;
    border-radius: 6px; 
    margin-top: 1rem;
  }

  .artwork-options {
    text-align: center;
    padding: 0 1.25rem;
  }

  .artwork-options label {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
  }

  .artwork-options select,
  .artwork-options input {
    width: 100%;
    margin-bottom: 1.25rem;
    font-size: 0.85rem;
    text-align: center;
  }

  .variant-thumbnail-preview img {
    max-width: 100%;
    height: auto;
    margin-top: 1.5rem;
  }

  .total-price h3 {
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .add-to-cart-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
  }

  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 85%;
    text-align: center;
  }

  .modal-content h2 {
    font-size: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .artwork-preview {
    max-width: 100%;
    height: auto;
    margin-bottom: 1.25rem;
  }

  .modal-content .close-button {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .checkout-button {
    width: 100%;
    padding: 0.85rem;
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .choose-your-artwork-container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: #ececec;
    min-height: calc(100vh - 10rem);
    flex-grow: 1;
  }

  .choose-your-artwork-container h2 {
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 0; 
  }

  .artwork-layout {
    display: grid;
    grid-template-columns: 1fr; 
    gap: 1.5rem;
    max-width: 90%; 
    margin: 0 auto;
    align-items: center; 
  }

  .artwork-details {
    text-align: center;
  }

  .artwork-image {
    max-width: 100%;
    height: auto;
    border: 3px solid #d7b46a;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .artwork-options {
    background-color: white;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .artwork-options label {
    font-size: 1rem;
    margin-bottom: 0.75rem;
   
  }

  .artwork-options select,
  .artwork-options input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
  }

  .total-price {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  .add-to-cart-button {
    background-color: #d7b46a;
    color: #1f1f1f;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .add-to-cart-button:hover {
    background-color: #b6953c;
  }

  .variant-thumbnail-preview {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .variant-thumbnail {
    width: 12rem;
    height: auto;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: transform 0.2s, border-color 0.2s;
  }

  .variant-thumbnail:hover {
    border-color: #d7b46a;
    transform: scale(1.1);
  }

  .modal-content {
    max-width: 320px; 
    padding: 1rem;
    text-align: center;
  }

  .modal-content h2 {
    font-size: 1.25rem;
    margin-top:1rem;
    margin-bottom:0;
  }

  .modal-content .close-button {
    margin-top: 8px;
    margin-right: -22px;
    
  }

  .checkout-button {
    padding: 0.75rem;
    font-size: 1rem;
    margin-top: 1.5rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .choose-your-artwork-container {
    padding: 2rem;
    background-color: #ececec;
    min-height: calc(100vh - 12rem); 
    flex-grow: 1;
  }

  .choose-your-artwork-container h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    padding-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 10rem; 
  }

  .artwork-layout {
    display: grid;
    grid-template-columns: 2fr 1fr; 
    gap: 2rem;
    max-width: 90%;
    margin: 0 auto;
  }

  .artwork-details {
    text-align: center;
  }

  .artwork-image {
    max-width: 100%;
    height: auto;
    border: 4px solid #d7b46a;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }

  .artwork-options {
    background-color: white;
    padding: 1.5rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
  }

  .artwork-options label {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .artwork-options select,
  .artwork-options input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
  }

  .total-price {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1.5rem 0;
  }

  .add-to-cart-button {
    background-color: #d7b46a;
    color: #1f1f1f;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .add-to-cart-button:hover {
    background-color: #b6953c;
  }

  .variant-thumbnail-preview {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .variant-thumbnail {
    width: 14rem;
    height: auto;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: transform 0.2s, border-color 0.2s;
  }

  .variant-thumbnail:hover {
    border-color: #d7b46a;
    transform: scale(1.1);
  }

  .modal-content {
    max-width: 380px;
    padding: 2rem;
    text-align: center;
  }

  .modal-content h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-left:0;
    font-size:1.3rem;
  }

  .modal-content .close-button {
    margin-top: 8px;
    margin-right: -18px;
  }

  .checkout-button {
    padding: 0.75rem;
    font-size: 1.125rem;
    margin-top: 2rem;
  }
}

@media (min-width: 1921px) {
  .choose-your-artwork-container {
    padding-top: 0;
    padding-bottom: 3rem;
      }

  .choose-your-artwork-container h2 {
    font-size: 2.5rem;
    margin-left: 30rem;
  }

  .artwork-details {
    width: 100%; 
    display: flex;
    justify-content: center;
  }

  .artwork-image {
    width: 100%; 
    max-width: 1200px;
    height: auto;
    border: 8px solid #d7b46a; 
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }

  .artwork-layout {
    max-width: 1600px;
    gap: 2rem;
  }

  .artwork-options select {
    font-size: 0.9rem; 
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    height: 2.5rem;
    box-sizing: border-box;
  }
  
  .artwork-options option {
    font-size: 0.7rem; 
  }
  

  .add-to-cart-button {
    font-size: 1.25rem;
    padding: 1rem 2rem;
  }

  .variant-thumbnail {
    width: 18rem;
  }

  .modal-content {
    max-width: 650px;
    padding: 2.5rem;
  }

  .modal-content h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-left:0;
    font-size:1.3rem;
  }

  .checkout-button {
    font-size: 1.25rem;
    padding: 1rem 2rem;
    width:60%;
  }

  .modal-content .close-button {
    font-size: 1.5rem;
    padding: 1rem;
  }

}


@media (min-width: 2561px) {
  .choose-your-artwork-container {
    padding-top: 0;
    padding-bottom: 3rem;
  }

  .choose-your-artwork-container h2 {
    font-size: 3rem;
    margin-left: 30rem;
  }

  .artwork-details {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .artwork-image {
    width: 100%;
    max-width: 1300px;
    height: auto;
    border: 10px solid #d7b46a;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  }

  .artwork-layout {
    max-width: 2200px;
    gap: 1rem;
  }

  .artwork-options select {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: auto;
    max-width: 100%;
    height: 2.5rem;
    box-sizing: border-box;
  }
  .artwork-options {
    padding: 2.5rem 3rem;
    font-size: 0.6rem;
    width: 100%; 
    max-width: 650px; 
  }

  .add-to-cart-button {
    font-size: 1.5rem;
    padding: 1.25rem 2.5rem;
  }

  .variant-thumbnail {
    width: 22rem;
  }

  .modal-content {
    max-width: 1060px;
    padding: 3.5rem;
  }

  .modal-content h2 {
    margin-bottom: 1rem;
    margin-left:0;
    font-size:2rem;
  }

  .modal-content p{
    font-size: 1.5rem;
  }

  .checkout-button {
    font-size: 1.5rem;
    padding: 1.25rem 2.75rem;
    width: 70%;
  }
}
