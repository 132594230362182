/* Contenidor principal per a Shipping Info i Cart Summary */
.containerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 80px;
  gap: 20px;
}

.shippingInfoContainer {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  gap: 20px;
}

.shippingInfoSection {
  width: 40%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* Shipping Method Section */
.shippingMethod {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  box-sizing: border-box;
}

.shippingOption {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.radioButton {
  margin-bottom: 5px;
}

.shippingLabel {
  flex-grow: 1;
  text-align: justify;
  font-size: 0.8rem;
  white-space: normal;
  overflow: visible;
  line-height: 1.4;
}

.shippingPrice {
  flex-shrink: 0;
  text-align: right;
}

.additionalInfo {
  font-size: 12px;
  width: 100%;
  margin-top: 10px;
  text-align: justify;
  background-color: #f7f7f7;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.additionalInfo h4 {
  color: #e63946;
  margin-top: 10px;
}

.additionalInfo p {
  margin-top: 5px;
  line-height: 1.4;
}

.cartSummary {
  width: 45%;
  padding: 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;
}

.removeButton {
  width: 80%;
  margin: auto;
  background-color: #1e1f1f;
  color: #d7b46a;
}

/* Adaptació per a dispositius mòbils */
/* Responsivitat per a 320px */
@media (max-width: 320px) {
  .shippingInfoContainer {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
  }

  .shippingInfoSection {
    width: 100%;
    padding: 1rem;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .shippingHeader {
    margin-bottom: 10px;
    text-align: center;
  }

  .divider {
    margin: 10px 0;
  }

  .userAddressSection p {
    font-size: 0.85rem;
    text-align: center;
  }

  .shippingMethod {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .shippingOption {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .shippingLabel {
    flex-grow: 1;
    text-align: left;
    font-size: 0.8rem;
    white-space: normal;
    overflow: visible;
    line-height: 1.4;
    max-width: 100%;
  }

  .shippingPrice {
    font-size: 0.8rem;
    margin-top: 5px;
  }

  .paymentButton {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .secureInfo {
    font-size: 0.7rem;
    justify-content: center;
    align-self: center;
  }

  .secureInfo img {
    width: 1rem;
    margin-left: 0.2rem;
  }

  .errorMessage {
    font-size: 0.7rem;
    text-align: center;
    margin-left: 0;
  }
}

/* Responsivitat per a 375px */
@media (max-width: 375px) {
  .shippingInfoContainer {
    flex-direction: column;
    width: 100%;
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .shippingInfoSection {
    width: 100%;
    padding: 1.5rem;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .shippingHeader {
    margin-bottom: 15px;
    text-align: center;
    font-size: 1.25rem;
  }

  .divider {
    margin: 15px 0;
  }

  .userAddressSection p {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .shippingMethod {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .shippingOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .radioButton {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .shippingLabel {
    margin-left: 0;
    text-align: left;
    font-size: 0.8rem;
    white-space: normal;
    overflow: visible;
    line-height: 1.4;
    max-width: 100%;
  }

  .shippingPrice {
    text-align: left;
    font-size: 0.8rem;
  }

  .paymentButton {
    font-size: 0.85rem;
    padding: 0.75rem;
    margin-top: 1rem;
  }

  .secureInfo {
    font-size: 0.75rem;
    justify-content: center;
    align-self: center;
    margin-top: 1rem;
  }

  .secureInfo img {
    width: 1.2rem;
    margin-left: 0.3rem;
  }

  .errorMessage {
    font-size: 0.75rem;
    text-align: center;
    margin-left: 0;
    margin-top: 1rem;
  }
}

/* Responsivitat per a 480px */
@media (max-width: 480px) {
  .containerWrapper {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    align-items: center;
  }

  .shippingInfoContainer {
    width: 100%;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .shippingInfoSection {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: none;
  }

  .cartSummary {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: none;
  }

  .shippingHeader {
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .divider {
    margin: 10px 0;
  }

  .userAddressSection p {
    font-size: 0.85rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .shippingMethod {
    flex-direction: column;
    gap: 10px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .shippingOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .radioButton {
    flex-shrink: 0;
    margin-bottom: 0;
  }

  .shippingLabel {
    flex-grow: 1;
    margin-left: 10px;
    text-align: left;
    font-size: 0.85rem;
    white-space: normal;
    overflow: visible;
    line-height: 1.4;
    max-width: 100%;
  }

  .shippingPrice {
    flex-shrink: 0;
    text-align: right;
    font-size: 0.85rem;
    margin-left: 10px;
  }

  .additionalInfo {
    font-size: 0.8rem;
    padding: 10px;
  }

  .paymentButton {
    width: 100%;
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .secureInfo {
    font-size: 0.8rem;
    justify-content: center;
    margin-top: 10px;
  }

  .secureInfo img {
    width: 1.2rem;
    margin-left: 0.3rem;
  }

  .errorMessage {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 10px;
  }

  .removeButton {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.85rem;
  }
}

/* Adaptació per a dispositius tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .containerWrapper {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .shippingInfoContainer {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .shippingInfoSection,
  .cartSummary {
    width: 100%;
    padding: 20px;
  }

  .additionalInfo {
    font-size: 0.875rem;
  }

  .shippingMethod {
    flex-direction: column;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .shippingOption {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .shippingLabel {
    flex-grow: 1;
    text-align: left;
    font-size: 0.8rem;
    white-space: normal;
    overflow: visible;
    line-height: 1.4;
    max-width: 100%;
  }

  .shippingPrice {
    flex-shrink: 0;
    text-align: right;
    font-size: 0.8rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .shippingInfoContainer {
    width: 100%;
    padding-left:3rem;
    padding-right:3rem;
    padding-top:2rem;
    padding-bottom:0;
  }

  .shippingInfoSection,
  .cartSummary {
    width: 50%;
    padding-right:2rem;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .shippingInfoContainer {
    width: 100%;
    padding-left:3rem;
    padding-right:3rem;
    padding-top:2rem;
    padding-bottom:0;
  }

  .shippingInfoSection,
  .cartSummary {
    width: 50%;
    padding-right:2rem;
  }
}


/* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .shippingInfoContainer {
    width: 90%;
    padding: 4rem;
    gap: 3rem;
  }

  .shippingInfoSection,
  .cartSummary {
    width: 45%;
    padding: 2.5rem;
    font-size: 1.2rem;
  }

  .shippingInfoSection {
    text-align: center;
  }

  .userAddressSection{
    line-height: 1.1rem;
    padding-top:0.5rem;
    padding-bottom:1rem;
  }

  .shippingOption {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }

  .radioButton {
    justify-self: start;
  }

  .shippingLabel {
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;
  }

  .shippingPrice {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: right;
  }

  .additionalInfo {
    font-size: 1rem;
    padding: 1.5rem;
  }

  .paymentButton {
    font-size: 1.1rem;
    padding: 1rem 1.5rem;
  }

  .secureInfo {
    font-size: 1rem;
  }

  .removeButton {
    font-size: 1rem;
    padding: 1rem;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
  .shippingInfoContainer {
    width: 85%;
    padding: 5rem;
    gap: 4rem;
  }

  .shippingInfoSection,
  .cartSummary {
    width: 50%;
    padding: 3rem;
    font-size: 1.6rem;
    
  }

  .userAddressSection{
    line-height: 1.1rem;
    padding-top:0.5rem;
    padding-bottom:1rem;
  }

  .shippingOption {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    margin-bottom: 2rem;
  }

  .radioButton {
    justify-self: start;
  }

  .shippingLabel label {
    font-size: 1.2rem; 
    line-height: 2rem;
    text-align: left;
  }

  .shippingPrice {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: right;
  }

  .additionalInfo {
    font-size: 1.1rem;
    padding: 2rem;
  }

  .paymentButton {
    font-size: 1.5rem;
    padding: 1.2rem 2rem;
  }

  .secureInfo {
    font-size: 1.1rem;
  }

  .removeButton {
    font-size: 1.1rem;
    padding: 1rem;
  }
}
