/* Contenidor Sign In */
.signin-container {
  display: flex;
  min-height: 100vh;
}

.image-section {
  flex: 1;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-section {
  position: relative;
  flex: 1;
  background-color: #333;
  color: #d7b46a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
}

.brand-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 4em;
  font-weight: 300;
  color: #d7b46a;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.message-section h2 {
  font-size: 20px;
  color: #d7b46a;
}

.message-section p {
  font-size: 16px;
  margin-top: 20px;
}

.message-section button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #222;
  color: #d7b46a;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.message-section button:hover {
  background-color: #b5974f;
  color: #222;
}


@media (max-width: 320px) {
  .message-section h2{
    margin-top:5rem;
  }
}

@media (max-width: 375px) {
  .message-section h2 {
    margin-top: 6rem; 
  }
}


/* Adaptació per a mòbils */
@media (max-width: 480px) {
  .signin-container {
    padding: 0px;
  }

  .brand-title {
    font-size: 2.5em;
  }

  .message-section h2 {
    font-size: 16px;
    margin-top: 6rem;
  }

  .message-section p {
    font-size: 13px;
  }
}

/* Adaptació per a tablets */
@media (max-width: 768px) {
  .signin-container {
    flex-direction: column;
 
  }

  .brand-title {
    font-size: 3em;
    top: 5%;
  }

  .message-section h2 {
    font-size: 18px;
    margin-top: 6rem;
  }

  .message-section p {
    font-size: 14px;
  }
}

/* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .message-section h2 {
    font-size: 42px;
  }

  .message-section p {
    font-size: 28px;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
  .message-section h2 {
    font-size: 52px;
  }

  .message-section p {
    font-size: 32px;
  }

  .message-section form input {
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
    width: 60%;
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }

  .message-section form button {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    width:60%;
  }

  .error-message,
  .success-message {
    font-size: 1.5rem;
  }
}
