/* -------------------------- Customization Section -------------------------- */

.customization-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem;
  background-color: #1f1f1f;
  position: relative;
  color:#d7b46a;
  

}

.customization-controls {
  margin-bottom: 1.25rem;
  text-align: center;
  z-index: 2;
  color:#d7b46a;
  
}

.customization-sketch {
  width: 80%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  position: relative;
  text-decoration: none;
}

/* posició del botó */
.finish-button {
  background-color: #d7b46a;
  color: #1f1f1f;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.9375rem 1.875rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 3;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
}

.finish-button:hover {
  background-color: #b6953c;
  transition: background-color 0.3s ease;
}

.finalize-button {
  margin-bottom: 1.25rem;
  z-index: 2;
}

/* Responsivitat */
@media (max-width: 768px) {
  .customization-container {
    padding: 1.5rem;
  }

  .finish-button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    bottom: 1rem;
    right: 1rem;
  }
}
