/* -------------------------- Checkout Page -------------------------- */

.containerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding-top: 1.25rem;
  padding-bottom: 5rem;
  gap: 1.25rem;
}

.checkoutContainer {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  gap: 1.25rem;
}

/* Checkout Form */
.checkoutForm {
  width: 40%;
  padding: 1.875rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}

/* Botó d'inici de sessió */
.signInButton {
  color: #1e1f1f;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 5px;
  margin-left: auto; 
  text-decoration: underline;
}

.signInButton:hover{
  color: #d7b46a;
}

/* Botó per codis promocionals */
.promoLink {
  background: none;
  border: none;
  color: #1e1f1f;
  text-decoration: underline;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  margin-bottom: 1.25rem;
  display: inline-block;
  white-space: nowrap;
  margin-left: 2px;
}

/* Botons de pagament */
.paymentOptions {
  display: flex;
  gap: 0.625rem;
  margin-top: 2rem;
  width: 100%;
}

.paypalButton,
.amazonButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.8125rem;
  background-color: #d7b46a;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.paypalButton img,
.amazonButton img {
  width: 50%;
  height: auto;
  display: block;
}

.amazonButton img {
  margin-top: 0.5rem;
}

/* Camps del formulari */
.formInput {
  margin-bottom: 0.625rem;
  padding: 0.5rem;
  border: 1px solid #1b1b1b;
  width: 100%;
  font-size: 0.875rem;
  color: #1b1b1b;
  background-color: #ffffff;
  box-sizing: border-box;
}

.formInput:focus {
  outline: none;
  border-color: #b5974f;
}

/* Files de camps d'entrada */
.formRow {
  display: flex;
  gap: 5rem;
  width: 100%;
}

.formRowLarge {
  width: 100%;
}

.formLabel {
  font-size: 0.75rem;
  color: #1e1f1f;
}

.backToGallery{
  margin-top: 2em;
  color: #1e1f1f;
  margin-left: auto; 
  text-decoration: underline; 
}

.backToGallery:hover{
  color: #d7b46a;
}

/* Botó 'Continue to shipping method' */
button[type="submit"] {
  background-color: #1e1f1f;
  color: #d7b46a;
  border: none;
  padding: 0.938rem;
  font-size: 0.875rem;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 1.25rem;
  text-align: center;
}

button[type="submit"]:hover {
  background-color: #d7b46a;
  color: #1e1f1f;
}

/* Secció de codi promocional */
.promoCodeHeader {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.promoCodeSection {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.promoCodeSection input {
  flex: 6;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.promoCodeSection button {
  flex: 4; 
  margin-bottom: 0;
  padding: 0.68rem;
  font-size: 0.8rem;
  cursor: pointer;
  box-sizing: border-box;
}

.promoError {
  color: red;
  font-size: 0.8rem;
  text-align: left; 
  margin-top: 4px;
  margin-left: 2px;
}

/* Estil per a 'Your information is secure' */
.secureInfo {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #999;
  margin-top: 0.625rem;
  align-self: flex-start;
}

.secureInfo img {
  width: 1.25rem;
  height: auto;
  margin-left: 0.3rem;
}

.removeButton {
  width: 80%;
  margin: auto;
}

.shippingNotice {
  font-size: 0.9rem;
}

/* Cart Summary */
.cartSummary {
  width: 45%;
  padding: 1.5625rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;
}

/* -------------------------- Responsive -------------------------- */

@media (max-width: 480px) {
  .containerWrapper {
    flex-direction: column;
    padding: 1rem;
    margin-top:0;
  }

  .checkoutContainer {
    width: 100%;
    flex-direction: column;
    padding-top:1rem;
    padding-bottom:0rem;
    padding-right:1rem;
    padding-left:1rem;
  }

  .checkoutForm,
  .cartSummary {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .formRow {
    flex-direction: column;
    gap: 1rem;
  }

  .formLabel {
    white-space: nowrap;
  }

  .promoCodeSection button {
    flex: 4; 
    margin-bottom: 0;
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
    cursor: pointer;
    box-sizing: border-box;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .containerWrapper {
    padding: 1rem 2rem;
  }

  .checkoutContainer {
    width: 100%;
    gap: 1rem;
    padding-top:1rem;
    padding-bottom:1rem;
  }

  .checkoutForm {
    width: 90%;
  }

  .cartSummary {
    width: 30%;
  }
  .promoCodeSection button {
    flex: 4; 
    margin-bottom: 0;
    padding: 0.2rem 0.7rem;
    font-size: 0.7rem;
    cursor: pointer;
    box-sizing: border-box;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .checkoutContainer {
    width: 90%;
    margin:auto;
    gap: 0;
  }

  .checkoutForm {
    width: 55%;
  }

  .cartSummary {
    width: 35%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .checkoutContainer {
    width: 80%;
  }
}

@media (min-width: 1921px) {
  .containerWrapper {
    padding: 2rem 5rem;
    gap: 2rem;
  }

  .checkoutContainer {
    width: 80%;
    padding: 3rem 0;
    gap: 3rem;
  }

  .checkoutForm {
    width: 60%;
    padding: 2.5rem;
    font-size: 1rem;
  }


    .checkoutForm label {
      font-size: 1.2rem;
    }
  
    .checkoutForm h3 {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  
    .checkoutForm .signInButton {
      font-size: 1.2rem;
    }
  
    .formInput {
      font-size: 1.2rem;
    }

  .cartSummary {
    width: 35%;
    padding: 2rem;
    font-size: 0.95rem;
  }

  .formRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 2rem;
    width: 100%;
  }

  .formRow .formInput {
    width: 100%;
    box-sizing: border-box;
  }

  .formRowLarge .formInput {
    grid-column: span 2; 
    width: 100%;
    box-sizing: border-box;
  }

  .promoCodeSection input {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .promoCodeSection button {
    padding: 0.8rem 1.2rem;
    font-size: 0.9rem;
  }

  button[type="submit"] {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
}

@media (min-width: 2561px) {
  .containerWrapper {
    padding: 3rem 6rem;
    gap: 3rem;
   
  }

  .checkoutContainer {
    width: 75%;
    padding: 4rem 0;
    gap: 4rem;
  }

  .checkoutForm {
    width: 65%;
    padding: 3rem;
    font-size: 1.6rem;
   
  }

    .checkoutForm label {
      font-size: 1.5rem;
    }
  
    .checkoutForm h3 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  
    .checkoutForm .signInButton {
      font-size: 1.5rem;
    }
  
    .formInput {
      font-size: 1.4rem;
    }

  .cartSummary {
    width: 30%;
    padding: 2.5rem;
    font-size: 1rem;
  }

  .formRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 3rem;
    width: 100%;
  }

  .formRow .formInput {
    width: 100%;
    box-sizing: border-box;
    font-size: 1.4rem;
  }

  .formRowLarge .formInput {
    grid-column: span 2; 
    width: 100%;
    box-sizing: border-box;
  }

  .promoCodeSection input {
    padding: 1rem 1.75rem;
    font-size: 1.4rem;
  }

  .promoCodeSection button {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }

  button[type="submit"] {
    padding: 1.25rem 2.5rem;
    font-size: 1.3rem;
  }
}
