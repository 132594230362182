/* -------------------- Register Page Styles -------------------- */
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15rem;
  padding-bottom: 5rem;
  width: 100%;
  margin: 0 auto;
}

.registerForm {
  width: 50%;
  padding: 1.875rem;
  border-radius: 8px;
  background-color: #ECECEC;
  box-shadow: 0 4px 10px rgba(236, 236, 236, 0.5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}

.register-title {
  font-size: 1.5rem;
  color: #b5974f;
  margin-bottom: 1.25rem;
  text-align: center;
  width: 100%;
}

/* Camps d'entrada del formulari */
.registerInput {
  margin-bottom: 0.625rem;
  padding: 0.5rem;
  border: 1px solid #1b1b1b;
  width: 100%;
  font-size: 0.875rem;
  color: #b5974f;
  background-color: #ffffff;
  box-sizing: border-box;
}

.registerInput:focus {
  outline: none;
  border-color: #b5974f;
}

.registerLabel {
  font-size: 0.75rem;
  color: #b5974f;
  margin-bottom: 0.25rem;
}

/* Botó de registre */
.registerButton {
  background-color: #1e1f1f;
  color: #d7b46a;
  border: none;
  padding: 0.938rem;
  font-size: 0.875rem;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 1.25rem;
  text-align: center;
}

.registerButton:hover {
  background-color: #d7b46a;
  color: #1e1f1f;
}

/* Missatge d'error */
.error-message {
  color: #e63946;
  font-size: 0.875rem;
  margin-top: 0.625rem;
  text-align: center;
  width: 100%;
}

.consent-section {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  gap: 8px;
  white-space: nowrap; 
}

.consent-label {
  margin-top: 8px;
  font-size: 0.7rem;
  line-height: 1.2;
  white-space: nowrap; 
}

.privacy-link {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

/* Responsivitat per dispositius petits */
@media (max-width: 375px) {
  .register-container {
    padding-top: 8rem; 
  }

  .registerForm {
    width: 90%; /* Assegura que el formulari ocupa la majoria de l'ampla */
  }

  .consent-section {
    flex-direction: column; /* Canvia la disposició a columna */
    align-items: center;    /* Centra els elements */
    justify-content: center;
    gap: 4px;                /* Redueix l'espai entre elements */
    white-space: normal;    /* Permet que el text es repliqui */
    width: 100%;            /* Ocupa tot l'ampla del contenidor */
  }

  .consent-label {
    font-size: 0.7rem;      /* Manté la mida de la font */
    text-align: center;     /* Centra el text */
    margin-top: 4px;        /* Ajusta el marge superior */
  }

  /* Opcional: Ajusta la mida de la font del checkbox si és necessari */
  .consent-section input {
    width: auto;            /* Assegura que la casella de selecció no sigui massa gran */
    height: auto;
  }
}

@media (max-width: 480px) {
  .register-container {
    padding-top: 8rem;
  }

  .registerForm {
    width: 90%;
  }

  .consent-label {
    font-size: 0.7rem;
  }
}

/* Responsivitat */
@media (max-width: 768px) {
  .registerForm {
    width: 90%;
  }
  .register-container {
    padding-top: 8rem;
  }
}

/* Pantalles més grans de 1921px */
@media (min-width: 1921px) {
  .registerForm {
    width: 70%;
  }

  .register-container {
    padding-top: 22rem;
  }
}

/* Pantalles més grans de 2561px */
@media (min-width: 2561px) {
  .registerForm {
    width: 70%;
    padding: 3rem;
  }

  .register-title {
    font-size: 3rem;
  }
}
